import { Api } from "./aws/request";
import { SITE_TYPES, getSiteType } from "../microSiteConfig"



export const getApp = () => {
  return Api.getAppConfig();
};

// const BASE_ASSET_URL = "http://prod-optiqueline-258565051107-site-assets.s3-ap-southeast-2.amazonaws.com"
const BASE_ASSET_URL = "https://d3i7dklp9u03xa.cloudfront.net";
export const queryProduct = async query => {
  let response = await Api.postRequest("/product/inventory/query", {
    data: query,
    metadata: {}
  });
  //{"attributes":{},"sort":{"dateuploaded":"DESC"},"limit":{"size":6,"page":0},"productinstance_clearanceitem":{"type":"equal","value":true}}
  response.data = Array.from(response.data).map(x => {
    return format_result(x);
  });
  return response;
};

export const getProduct = async id => {
  let response = await Api.postRequest("/product/inventory/query", {
    data: {
      attributes: {
        productid: {
          type: "like",
          value: id
        },
        productinstance_ordertype: {
          type: "in",
          value: [
            "available",
            "backordable"
          ]
        }
      },
      sort: {},
      limit: {
        page: 0,
        size: 1
      }
    },
    metadata: {}
  });
  let product = response.data[0];
  return format_result(product);
};

function coloursort(a,b){

  if(a.colour > b.colour){
    return 1;
  }
  else if(a.colour < b.colour ){
    return -1;
  } else {

    if(a.title > b.title){
      return 1;
    }
    else if(a.title < b.title ){
      return -1;
    } else {
      return 0;
    }
  }


}

const format_result = product => {
  const SITETYPE = getSiteType();



  product.productinstance = Array.from(product.productinstance).map(i => {
    i.image = `${BASE_ASSET_URL}/${i.cellasset}`;
    i.imageDefault = `${BASE_ASSET_URL}/cdn/media/catalog/product/placeholder/default/Optique_Line_Watermark_3.png`;
    i.imageViewer = `${BASE_ASSET_URL}/${i.pageasset}`;
    i.normalprice = SITETYPE === SITE_TYPES.AU ? i.priceaud : i.pricenzd;

    if( i.clearanceitem && SITETYPE === SITE_TYPES.AU ){
      i.clearanceprice = i.priceclearanceaud;
      i.price = i.clearanceprice;
    } else if( i.clearanceitem && SITETYPE === SITE_TYPES.NZ ){

      if(i.priceclearancenzd || i.priceclearanceaud === 0){

        i.clearanceprice = i.priceclearancenzd;
        i.price = i.clearanceprice;
      } else {
        i.price = i.normalprice;
        i.clearanceitem = false;
      }

    } else {
      i.price = i.normalprice;
    }

    if(i.model.length === 5){

      var imgCheck = i.largeimage.split('-');
      if(imgCheck && imgCheck.length > 2 && imgCheck[1].length>5 && imgCheck[1].indexOf(i.model)===0){
        i.modelnotdisplayfixed = i.model;
        i.model = imgCheck[1];
        
      }

    }

    //i.clearanceprice = SITETYPE === SITE_TYPES.AU ? i.priceclearanceaud : i.priceclearancenzd;
   // i.price = i.clearanceitem ? i.clearanceprice : i.normalprice;
    return i;
  });
  product.productinstance.sort(coloursort);
  return product;
};
