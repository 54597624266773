import React from 'react';
import Grid from '@material-ui/core/Grid';
import { connect } from "react-redux";

import ClearanceItem from '../../components/clearance-item/ClearanceItem';
import { selectProductInstances } from '../../redux/products/products.selectors';
import { createStructuredSelector } from 'reselect';

import NoteIcon from '@material-ui/icons/Note';
import CenteredOverlay from '../../components/no-product-found/Centered.styles';
import { Typography } from '@material-ui/core';
import { makeStyles } from "@material-ui/core";

import { getFilters } from "../../microSiteConfig";

const theFilters = getFilters();
const useStyles = makeStyles({
    icon:{
        width:"30vh"
    }
}) 

function ProductFilterNameMatch(subbrand, subbrandname){

    let thisfilter = theFilters.subbrand.filter;

    if( thisfilter.hasOwnProperty(subbrand) ) {
        let filteritem = thisfilter[subbrand];
        if(filteritem.label){
            return filteritem.label;
        }
    }

    return subbrandname;
}

function ProductList(props) {
    const classes = useStyles()
    return (
        <Grid container spacing={3} alignItems="center" >
            {Array.from(props.product).length !== 0 ? 
            Array.from(props.product).map((product, productIndex) => (
                <Grid  key={productIndex} item lg={3} md={6} sm={6}>
                    <ClearanceItem key={productIndex} product={product} productfiltername={ProductFilterNameMatch(product.subbrand, product.subbrandname)} />
                </Grid>
            )) : <CenteredOverlay><NoteIcon className={classes.icon}/><Typography>No Product Found</Typography></CenteredOverlay>}
        </Grid>
    )

}




const mapStateToProps = createStructuredSelector ({
    product: selectProductInstances,
})


   


export default connect(mapStateToProps,null)(ProductList);
