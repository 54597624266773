import {getNavigationPaths} from './navigation'

export const AssetBucket = "https://d3i7dklp9u03xa.cloudfront.net"


export const getTerritoryManagers = () => ({
    Victoria: {
        managers: [
          {
            name: "JOHN NICOLA",
            phoneNumber: "0418 322 658",
            phoneNumberLink: "+61418322658",
            email: "john@optiqueline.com.au"
          },
          {
            name: "ANDREW CRAIB",
            phoneNumber: "0400 168 754",
            phoneNumberLink: "+61400168754",
            email: "acraib@optiqueline.com.au"
          },
          {
            name: "ROY PARKER",
            phoneNumber: "0435 940 775",
            phoneNumberLink: "+61435940775",
            email: "roy@optiqueline.com.au"
          }
        ]
      },
      Tasmania: {
        managers: [
          {
            name: "ANDREW CRAIB",
            phoneNumber: "0400 168 754",
            phoneNumberLink: "+61400168754",
            email: "acraib@optiqueline.com.au"
          }
        ]
      },
      "South Australia": {
        managers: [
          {
            name: "ROY PARKER",
            phoneNumber: "0435 940 775",
            phoneNumberLink: "+61435940775",
            email: "roy@optiqueline.com.au"
          }
        ]
      },
      "New South Wales": {
        managers: [
          {
            name: "JOHN POLLACK",
            phoneNumber: "0412 258 875",
            phoneNumberLink: "+61412258875",
            email: "jpollack@optiqueline.com.au"
          },
          {
            name: "BETH BIRCH",
            phoneNumber: "‭0413 837 383‬",
            phoneNumberLink: "+61413837383‬",
            email: "beth@optiqueline.com.au"
          }
        ]
      },
      "Western Australia": {
        managers: [
          {
            name: "ANDREW CRAIB",
            phoneNumber: "0400 168 754",
            phoneNumberLink: "+61400 168 754",
            email: "acraib@optiqueline.com.au"
          }
        ]
      },
      "QUEENSLAND & NORTHERN TERRITORY": {
        managers: [
          {
            name: "DOUG STABE",
            phoneNumber: "0418 782 848",
            phoneNumberLink: "+61418782848",
            email: "doug@optiqueline.com.au"
          },
          {
            name: "JASON HANRAHAN",
            phoneNumber: "0414 928 787",
            phoneNumberLink: "+61414928787",
            email: "jason@optiqueline.com.au"
          }
        ]
      }

});

export const getAssets = () => {
  const paths = getNavigationPaths()
  return {
      logo: "/ol-main-logo.png",
      carousel: [
        `${AssetBucket}/homepage/a-l4.png`,
        `${AssetBucket}/homepage/b-l4.png`,
        `${AssetBucket}/homepage/c-l4.png`,
        `${AssetBucket}/homepage/d-l4.png`,
        `${AssetBucket}/homepage/e-l4.png`,
        `${AssetBucket}/homepage/f-l4.png`,
        `${AssetBucket}/homepage/h-l4.png`,
        `${AssetBucket}/homepage/i-l4.png`
      ],
      glasses: [
          {
              img:  `${AssetBucket}/homepage/stepper-sts-30035@2x.jpg`,
              link: "/productDetail?productId=30035-54-14"
          },
          {
              img: `${AssetBucket}/homepage/convertibles-91009@2x.jpg`,
              link: "/productDetail?productId=91009-53-16"
          },
          {
              img: `${AssetBucket}/homepage/aura-flex-628@2x.jpg`,
              link: "/productDetail?productId=628-53-18"
          },
          {
              img: "/stepper-sts-30114@2x.jpg",
              link: "/productDetail?productId=30114-53-13"
          },
          {
              img: `${AssetBucket}/homepage/mersey-beat-MORGA@2x.jpg`,
              link: "/productDetail?productId=MORGA-50-17"
          },
          {
              img: `${AssetBucket}/homepage/stepper-si-85037@2x.jpg`,
              link: "/productDetail?productId=85037-53-17"
          }
      ],
      homeGridRow1: [
          {
              img: `${AssetBucket}/homepage/1.jpg`,
              link: paths.convertiblesPromo
          },
          {
              img: `${AssetBucket}/homepage/2.jpg`,
              link: paths.stepperPromo
          }
      ],
      homeGridRow2: [
          {
              img: `${AssetBucket}/homepage/home-row2-marketingsupport.jpg`,
              title: "MARKETING SUPPORT",
              link: paths.marketingSupport
          },
          {
              img: `${AssetBucket}/homepage/Brands_SMALL_BLOCKS2.jpg`,
              title: "BRANDS",
              link: paths.brands
          },
          {
              img: `${AssetBucket}/homepage/accessories_SMALL_BLOCKS.jpg`,
              title: "CASES & CLOTHS",
              link: paths.accessories
          },
          {
              img: `${AssetBucket}/homepage/Clearance_SMALL_BLOCKS4.jpg`,
              title: "CLEARANCE ITEMS",
              link: paths.clearance
          }
      ],
      homeGridRow3: [
          {
              img: `${AssetBucket}/homepage/3.jpg`,
              link: paths.shop
          }
      ],
      homeMiddleSentenceText: 
        {
          label: 'The Brands With Vision'
        }
      ,
      homeGridRow4: [
          {
              img: `${AssetBucket}/homepage/home-row4-partnerprogram.jpg`,
              title: "PARTNER PROGRAM",
              link: paths.contactUs
          },
          {
              img: `${AssetBucket}/homepage/home-row4-seeandsave.jpg`,
              title: "FRAME & LENS",
              link: paths.contactUs
          },
          {
              img: `${AssetBucket}/homepage/home-row4-labdirect.jpg`,
              title: "LAB DIRECT",
              link: paths.contactUs
          },
          {
              img: `${AssetBucket}/homepage/home-row4-newsletter.jpg`,
              title: "NEWSLETTER",
              link: paths.newsLetter
          }
      ],
      sideColumnLinks1: [
          {
              img: `${AssetBucket}/homepage/home-row4-partnerprogram.jpg`,
              title: "PARTNER PROGRAM",
              link: paths.contactUs
          },
          {
              img: `${AssetBucket}/homepage/home-row4-labdirect.jpg`,
              title: "LAB DIRECT",
              link: paths.contactUs
          },
          {
              img: `${AssetBucket}/homepage/home-row2-marketingsupport.jpg`,
              title: "MARKETING SUPPORT",
              link: paths.marketingSupport
          }
      ],
      sideColumnLinks2: [
          {
              img: `${AssetBucket}/homepage/1.jpg`,
              link: paths.convertiblesPromo
          },
          {
              img: `${AssetBucket}/homepage/2.jpg`,
              link: paths.stepperPromo
          }
      ],
      description: [
          {
              type: "h1",
              body: "helkle"
          }
      ],
      landingButtonRow: [
          {
              label: 'Contact Us',
              link: paths.contactUs
          },
          {
              label: 'Subscribe to Newsletter',
              link: paths.newsLetter
          }
      ]
  }
}
