import { AssetBucket } from '../default/assets';
import { getNavigationPaths } from './navigation';

export const getAssets = () => {
    const paths = getNavigationPaths()
    return {
        logo: `${AssetBucket}/merseybeateyewear.com.au/logo.png`,
        carousel: [
            `${AssetBucket}/merseybeateyewear.com.au/lookbook_images_01_1.jpg`,
            `${AssetBucket}/merseybeateyewear.com.au/lookbook_images_02_1.jpg`,
            `${AssetBucket}/merseybeateyewear.com.au/lookbook_images_04_1.jpg`
        ],
        //footerLogo: require("../../assets/img/merseybeat_footer.png"),
        sideColumnLinks1: [
            {
                img: `${AssetBucket}/homepage/home-row4-partnerprogram.jpg`,
                title: "PARTNER PROGRAM",
                link: '/contact-the-team/'
            },
            {
                img: `${AssetBucket}/homepage/home-row4-labdirect.jpg`,
                title: "LAB DIRECT",
                link: '/contact-the-team/'
            },
            {
                img: `${AssetBucket}/homepage/home-row2-marketingsupport.jpg`,
                title: "MARKETING SUPPORT",
                link: paths.marketingSupport
            }
        ],
        sideColumnLinks2: [
            {
                img: `${AssetBucket}/homepage/1.jpg`,
                link: paths.convertiblesPromo
            },
            {
                img: `${AssetBucket}/homepage/2.jpg`,
                link: paths.stepperPromo
            }
        ],
        homeMiddleSentenceText:
        {
            label: ''
        }
        ,
        optional_text: "about_merseybeat",
        description: [
            {
                type: "h1",
                body: "helkle"
            }
        ],
        landingButtonRow: [
            {
                label: 'Shop the Range',
                link: paths.shop
            }
        ],
    }
}