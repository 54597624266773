//@ts-check

import React, { useState, useEffect } from "react";
import "./checkoutItem.styles.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  addItem,
  minusItem,
  removeItem,
  addItemToLab,
  setJobNum,
  setReference,
  minusItemToLab,
  updateQuantity,
  updateCartItemLabQuantity,
  setCartItemLabDirectEnabled
} from "../../redux/cart/cart.actions";

import { createCartItemSelector } from "../../redux/cart/cart.selectors";

import { Controller, useFormContext } from 'react-hook-form';
import * as yup from "yup"

import {
  TableRow,
  TableCell,
  Checkbox,
  Fade,
  TextField,
  Typography,
  CardMedia,
  FormControl,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { labDirectEnabled } from "../../redux/cart/cart.mutations";
import { BackupImageLoader } from "../BackupImageLoader";
import { properRounding } from "../../utils/helpers";
const useStyles = makeStyles({
  arrow: {
    display: "flex",
    padding: "16px"
  },
  quantity: {
    padding: "16px"
  },
  labDirectInput: {
    fontSize: "14px"
  },
  labDirectRow: {
    backgroundColor: "#34b3d2",
    // width: "100%",
    fontSize: "14px",
    "& *": {
      color: "white"
    }
  }
});

export const attr = {
  labdirect: {key: "labdirect", label: "Lab Direct"},
  labdirectjob: {key: "labdirectjob", label: "Job Number"},
  labdirectref: {key: "labdirectref", label: "Reference"},
  labdirectquantity: {key: "labdirectquantity", label: "Lab Direct Quantity"},
  quantity: {key: "quanitity", label: "Quantity"}
}

const MAX_NUM_ORDERS = 10000 // 10,000

export const CartItemValidation = yup.object().shape({
  [attr.labdirect.key]: yup.boolean().label(attr.labdirect.label),
  [attr.labdirectjob.key]: yup.string().label(attr.labdirectjob.label),
  [attr.labdirectref.key]: yup.string().label(attr.labdirectref.label),
  [attr.labdirectquantity.key]: yup.number().integer().when(attr.labdirect.key, {
    is: true, then : yup.number().integer().moreThan(0).lessThan(MAX_NUM_ORDERS).required().label(attr.labdirectquantity.label)
  }),
 // [attr.quantity.key]: yup.number().integer().when(attr.labdirect.key, {
 //   is: true, then: yup.number().integer().when(attr.labdirectquantity.key, {
  //    is: "0", then: yup.number().integer(),
 //     otherwise: yup.number().integer().moreThan(-1)
 //   }), otherwise: yup.number().integer().moreThan(0)
 // }).lessThan(MAX_NUM_ORDERS).required().label(attr.quantity.label),
});

const CheckoutItem = props => {
  const { data, state } = props.item;
  const { image, productinstanceid, title, imageBackup, imageDefault } = data;
  const { labDirectEnabled = false } = state;

  const classes = useStyles();

  const handleQuantityChange = type => event => {
    console.log("updating cart quantity", type);
    switch (type) {
      case "add:store":
        props.addItem(data,false,true);
        return;
      case "sub:store":
        props.minusItem(data,false,true);
        return;
      case "set:store":
        props.updateQuantity(data, event.target.value, false,true);
        return;
      case "add:lab":
        props.addItemToLab(data,false,true);
        return;
      case "sub:lab":
        props.minusItemToLab(data,false,true);
        return;
      case "set:lab":
        props.updateCartItemLabQuantity(data, event.target.value, false,true);
        return;
      case "remove":
        props.removeItem(data);
        return;
      default:
        return;
    }
  };
  const handleCheckboxChange = event => {
    props.setCartItemLabDirectEnabled(data, !labDirectEnabled);
  };
  const context = useFormContext()
  const errs = context.errors[`formcart`] ? context.errors[`formcart`][props.index] : null

  return (
    <>
      <TableRow key={data.largeimage}>
        <TableCell align="center">
          <Checkbox
            //value="primary"
            inputProps={{ "aria-label": "primary checkbox" }}
            checked={labDirectEnabled}
            onChange={handleCheckboxChange}
            name={`formcart[${props.index}].${attr.labdirect.key}`}
            inputRef={props.validation.register}
            value={labDirectEnabled}
            defaultValue={labDirectEnabled}
          />
        </TableCell>
        <TableCell align="center">
          <BackupImageLoader
            images={[image,imageBackup]}
            style={{ width: "200px", minHeight: "75px" }}
            safe={false}
          />
        </TableCell>
        <TableCell align="center">
          <Typography>
            {data.brand} {data.model}
          </Typography>
          <Typography variant="body2">
            Colour: {data.colour===null?(data.productinstanceid.split("-").pop()):data.colour} {data.title}
          </Typography>
        </TableCell>
        <TableCell align="center">${properRounding(data.price,2,1)}</TableCell>
        <TableCell align="center" style={{minWidth: "130px"}}>

            <span
            style={{ cursor: "pointer" }}
            className={`arrow ${state.storeDirectNum === 0 ? "disabled" : ""}`}
            onClick={handleQuantityChange("sub:store")}
          >
            &#10094;
          </span>

          <TextField
            type="number"
            style={{ margin: "0 5px" }}
            inputProps={{
              style: { textAlign: "center", width: "50px", padding: "5px 0" },
              min: "0",
              type: "number"
            }}
            variant="filled"
            error={errs && typeof errs[attr.quantity.key] !== "undefined"}
            onChange={handleQuantityChange("set:store")}
            name={`formcart[${props.index}].${attr.quantity.key}`}
            inputRef={context.register}
            value={state.storeDirectNum}
          />

          <span style={{ cursor: "pointer" }} className="arrow" onClick={handleQuantityChange("add:store")}>
            &#10095;
          </span>
          
          {/*<NestedQuantityInput
            name={attr.quantity.key}
            index={props.index}
            addItem={handleQuantityChange("add:store")}
            removeItem={handleQuantityChange("sub:store")}
            setQuantity={handleQuantityChange("set:store")}
            quantity={state.storeDirectNum}
          />
          */}
{(state.customstockmessage?
          <div dangerouslySetInnerHTML={{__html: state.customstockmessage}} className={`custom-cart-stock-message`} style={{color: "red"}} />
          :
          null
          )}
        </TableCell>
        <TableCell align="center">${properRounding(state.totalPrice,2,1)}</TableCell>
        <TableCell
          align="center"
          style={{ cursor: "pointer" }}
          className="remove-button"
          onClick={handleQuantityChange("remove")}
        >
          &#10005;
        </TableCell>
      </TableRow>
      {labDirectEnabled && (
        <LabDirect
          state={state}
          data={data}
          handleQuantityChange={handleQuantityChange}
          setJobNum={props.setJobNum}
          setReference={props.setReference}
          validation={props.validation}
          formcart={props.formCart}
          index={props.index}
        />
      )}
    </>
  );
};

function LabDirect(props) {
  const classes = useStyles();

  const handleJobNum = event => {
    props.setJobNum(props.data, event.target.value);
  };

  const handleReference = event => {
    props.setReference(props.data, event.target.value);
  };

  const context = useFormContext()
  const errs = context.errors[`formcart`] ? context.errors[`formcart`][props.index] : null
  return (
    <TableRow className={classes.labDirectRow}>
      <TableCell colSpan={2}>
        <Typography style={{color: "white", fontSize: "15px"}}>
          1.Enter your Job Number for the LAB.
          <br />
          2.Enter your Reference Number for the LAB.
          <br />
          3.Enter the quantity of frames your would like to send to the LAB.
        </Typography>
      </TableCell>
      <TableCell>
        <TextField
          inputProps={{
            style: { padding: "18px 25px", fontSize: 14, backgroundColor: "white", color: "black", textAlign: "center" }
          }}
          InputLabelProps={{ style: { fontSize: 14 } }}
          placeholder="Job Number"
          value={props.state.jobNum}
          onChange={handleJobNum}
        />
      </TableCell>
      <TableCell>
        <TextField
          inputProps={{
            style: { padding: "18px 25px", fontSize: 14, backgroundColor: "white", color: "black", textAlign: "center" }
          }}
          InputLabelProps={{ style: { fontSize: 14 } }}
          placeholder="Reference"
          value={props.state.reference}
          onChange={handleReference}
        />
      </TableCell>
      <TableCell align="center" style={{minWidth: "130px"}}>
        <span
          style={{ cursor: "pointer" }}
          className={`arrow ${props.state.labDirectNum === 0 ? "disabled" : ""}`}
          onClick={props.handleQuantityChange("sub:lab")}
        >
          &#10094;
        </span>
        <TextField
          type="number"
          style={{ margin: "0 5px" }}
          inputProps={{
            style: { textAlign: "center", width: "50px", padding: "5px 0" },
            min: "0",
            type: "number"
          }}
          variant="filled"
          error={errs && typeof errs[attr.labdirectquantity.key] !== "undefined"}
          onChange={props.handleQuantityChange("set:lab")}
          name={`formcart[${props.index}].${attr.labdirectquantity.key}`}
          inputRef={context.register}
          value={props.state.labDirectNum}
        />
        <span style={{ cursor: "pointer" }} className="arrow" onClick={props.handleQuantityChange("add:lab")}>
          &#10095;
        </span>
        {/*
        <NestedQuantityInput
          name={attr.labdirectquantity.key}
          index={props.index}
          addItem={props.handleQuantityChange("add:lab")}
          removeItem={props.handleQuantityChange("sub:lab")}
          setQuantity={props.handleQuantityChange("set:lab")}
          quantity={props.state.labDirectNum}
        />
        */}
{(props.state.customstockmessagelab?
          <div dangerouslySetInnerHTML={{__html: props.state.customstockmessagelab}} className={`custom-cart-stock-message`}  style={{color: "white"}} />
          :
          null
          )}
      </TableCell>
      <TableCell colSpan={2}></TableCell>
    </TableRow>
  );
}

const NestedQuantityInput = ({ name, index, addItem, removeItem, setQuantity, quantity }) => {
  const context = useFormContext()
  const val = context.watch(`formcart[${index}].${name}`)
  if (val != quantity)  {
    context.setValue(`formcart[${index}].${name}`, quantity)
  }
  const errs = context.errors[`formcart`] ? context.errors[`formcart`][index] : null
  return (
    <>
      <span
        style={{ cursor: "pointer" }}
        className={`arrow ${quantity === 0 ? "disabled" : ""}`}
        onClick={removeItem}
      >
        &#10094;
      </span>
      <TextField
        type="number"
        style={{ margin: "0 5px" }}
        inputProps={{
          style: { textAlign: "center", width: "50px", padding: "5px 0" },
          min: "0",
          type: "number"
        }}
        variant="filled"
        error={errs && typeof errs[name] !== "undefined"}
        onChange={setQuantity}
        name={`formcart[${index}].${name}`}
        inputRef={context.register}
        value={quantity}
      />
      <span style={{ cursor: "pointer" }} className="arrow" onClick={addItem}>
        &#10095;
      </span>
    </>
  )
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      addItem,
      updateQuantity,
      minusItem,
      removeItem,
      setJobNum,
      setReference,
      addItemToLab,
      minusItemToLab,
      updateCartItemLabQuantity,
      setCartItemLabDirectEnabled
    },
    dispatch
  );

// const mapStateToProps = (state,props) => ({
//   item: createCartItemSelector(state,props.key),
// });

const makeMapStateToProps = () => {
  const selector = createCartItemSelector();
  const mapStateToProps = (state, ownProps) => {
    return {
      item: selector(state, ownProps)
    };
  };
  return mapStateToProps;
};

export default connect(makeMapStateToProps, mapDispatchToProps)(CheckoutItem);
