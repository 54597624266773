import React, { useState, useRef, useEffect } from "react";
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import {
    Typography 
    ,Select 
    ,MenuItem 
    ,FormControl 
    ,InputLabel 
    ,Button 
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));






export const Pager = (props) => {
    const classes = useStyles();



    const updatePage = (pageNum) => {
        props.changePagination(pageNum)
    }
    useEffect(() => () => { // <-- Now we return the useEffect teardown effect, which will be fired only after the path/search change for the first time
        try {
          // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
          window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
          });
        } catch (error) {
          // just a fallback for older browsers
          window.scrollTo(0, 0);
        }
      }, [props.meta.offset]);

    return (
        <Grid container direction="row" justify="flex-end" alignItems="center">

            <Grid item>
                <Typography>
                    Page {props.meta.offset + 1} of {Math.ceil(props.meta.count / props.meta.limit)}
                </Typography>

            </Grid>

            <Grid item>

                <Button disabled={props.meta.offset === 0} aria-label="Back" className={classes.margin} onClick={() => updatePage(props.meta.offset - 1)}>
                    <ChevronLeft />
                </Button>
                <Button disabled={(props.meta.offset + 1) >= Math.ceil(props.meta.count / props.meta.limit)} aria-label="Back" className={classes.margin} onClick={() => updatePage(props.meta.offset + 1)} >
                    <ChevronRight />
                </Button>
            </Grid>

        </Grid>
    )
}


export default function Pagination(props) {
    const classes = useStyles();


    // Set the width of the labels
    const inputLabel = useRef(null);
    const [labelWidth, setLabelWidth] = useState(0);

    const handleOrderChange = (event) => {
        const index = event.target.value
        const order = props.paginationProps.orderBy[index]
        props.handleOrderChange({ key: order.value, sort: order.sort, index: index })
    }


    useEffect(() => {
        setLabelWidth(inputLabel.current.offsetWidth);
    });

    const showingStart = props.meta.offset * props.meta.limit + 1
    const showingTotal = props.meta.count
    const showingEnd = Math.min(showingStart + props.meta.limit - 1, showingTotal)

    return (
        <form>

            <Grid container direction="row" justify="space-between" alignItems="center">
                <Grid item>
                    <Typography >
                        Showing {showingStart} to {showingEnd} of {showingTotal}
                    </Typography>
                </Grid>

                <Grid item>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel ref={inputLabel} htmlFor="page-limit">
                            Limit
                        </InputLabel>
                        <Select
                            value={props.meta.limit}
                            onChange={props.handleLimitChange}
                            labelWidth={labelWidth}
                            name="limit"
                            inputProps={{
                                name: 'limit',
                                id: 'page-limit'
                            }}
                        >

                            {props.paginationProps.limits.map((limit, limitIndex) => (
                                <MenuItem key={limitIndex} value={limit}>{limit}</MenuItem>
                            ))}


                        </Select>
                    </FormControl>
                </Grid>

                <Grid item>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel ref={inputLabel} htmlFor="page-order">
                            Order by
                            </InputLabel>
                        <Select
                            value={props.meta.orderby}
                            onChange={handleOrderChange}
                            labelWidth={labelWidth}
                            name="order"
                            inputProps={{
                                name: 'order',
                                id: 'page-order'
                            }}
                        >

                            {props.paginationProps.orderBy.map((order, orderIndex) => (
                                <MenuItem key={orderIndex} value={orderIndex}>{order.label}</MenuItem>
                            ))}


                        </Select>
                    </FormControl>
                </Grid>


                <Grid item xs={12} lg={4}>
                    <Pager meta={props.meta} changePagination={props.changePagination}/>
                </Grid>


            </Grid>
        </form>
    );

}