import {
  updateCartItemQuantity,
  updateCartItemLabQuantity,
  addCartItem,
  reAddCartItem,
  setJobNum,
  setReference,
  minusCartItem,
  addLabDirectItem,
  minusLabDirectItem,
  setLabChecked,
  removeProduct,
} from "./cart.utils";

import { CartActionTypes } from "./cart.types";
import { INITIAL_STATE } from "../cart/cart.state";

const cartReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CartActionTypes.SET_JOB_NUM:
      return {
        ...state,
        cartItems: setJobNum(state, action.payload)
      };
    case CartActionTypes.SET_REFERENCE:
      return {
        ...state,
        cartItems: setReference(state, action.payload)
      };
    case CartActionTypes.UPDATE_QUANTITY:
      return {
        ...state,
        cartItems: updateCartItemQuantity(state, action.payload)
      };
    case CartActionTypes.UPDATE_QUANTITY_LAB:
      return {
        ...state,
        cartItems: updateCartItemLabQuantity(state, action.payload)
      };
    case CartActionTypes.ADD_ITEM:
      return {
        ...state,
        cartItems: addCartItem(state, action.payload)
      };
    case CartActionTypes.RE_ADD_ITEM:
      return {
        ...state,
        cartItems: reAddCartItem(state, action.payload, action.checks)
      };
    case CartActionTypes.MINUS_ITEM:
      return {
        ...state,
        cartItems: minusCartItem(state, action.payload)
      };
    case CartActionTypes.REMOVE_ITEM:
      return {
        ...state,
        cartItems: removeProduct(state, action.payload)
      };
    case CartActionTypes.SET_STORE_DIRECT_DETAIL_REFERENCE:
      return {
        ...state,
        storeDirectDetailReference: action.payload
      };
    case CartActionTypes.SET_STORE_DIRECT_DETAIL_COMMENTS:
      return {
        ...state,
        storeDirectDetailComments: action.payload
      };
    case CartActionTypes.SET_LAB_DIRECT_KEY:
      return {
        ...state,
        labDirectKey: action.payload
      };
    case CartActionTypes.SET_LAB_DIRECT_VALUE:
      return {
        ...state,
        labDirectValue: action.payload
      };
    case CartActionTypes.SET_LAB_DIRECT_STATE:
      var data = { ...state };
      data["labDirectState"] = action.payload;
      return data;
    case CartActionTypes.SET_STORE_DIRECT_STATE:
      var data = { ...state };
      data["storeDirectState"] = action.data;
      return data;
    case CartActionTypes.FETCH_COUPON_SUCCESS:
      var data = { ...state };
      data["coupon"] = {};
      data["coupon"]["data"] = action.data;
      return data;
    case CartActionTypes.FETCH_COUPON_ERROR:
      var data = { ...state };
      data["coupon"] = {};
      data["coupon"]["error"] = action.data;
      return data;
    case CartActionTypes.ADD_LAB_DIRECT_ITEM:
      return {
        ...state,
        cartItems: addLabDirectItem(state, action.payload)
      };
    case CartActionTypes.SET_SHIPPING_OPTION:
      var data = { ...state };
      data["shippingOption"] = action.payload;
      return data;
    case CartActionTypes.SET_CART_ITEM_LAB_ENABLED:
      return {
        ...state,
        cartItems: setLabChecked(state, action.payload)
      };
    case CartActionTypes.MINUS_LAB_DIRECT_ITEM:
      return {
        ...state,
        cartItems: minusLabDirectItem(state, action.payload)
      };
    case CartActionTypes.GET_LAB_DIRECT_OPTIONS:
      return {
        ...state,
        options: action.options
      };
    case CartActionTypes.IS_FETCHING:
      return {
        ...state,
        isFetching: true
      };
    case CartActionTypes.NOT_FETCHING:
      return {
        ...state,
        isFetching: false
      };
    case CartActionTypes.CLEAR_CART: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
};

export default cartReducer;
