export const getPaging = () => {
    return {
        limits: [3, 6, 12, 24, 36],
        orderBy: [
            {
                label: "Newest",
                value: "specialfromdate",
                sort: 'DESC'
            },
            {
                label: "Brand",
                value: "subbrandname",
                sort: 'ASC'
            
            },
            {
                label: "Price",
                value: "productinstance_priceaud",
                sort: 'ASC'
            },
            {
                label: "Model",
                value: "productinstance_model",
                sort: 'ASC'
            }
        ]
    }
}