import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { 
  SignInForm 
  , ChangePasswordModalForm
  , RegisterForm
  , ForgotPasswordStepOneForm
  , ForgotPasswordStepTwoForm
} from "../accountForms"
import {
  Grid,
  Typography,
  TextField,
  Button,
  Fade,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Checkbox,
  Link,
  IconButton,
  useMediaQuery,
  ButtonGroup
} from "@material-ui/core";

import MuiAlert from "@material-ui/lab/Alert";

// State Libraries
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { createStructuredSelector } from "reselect";

import {
  loginUser,
  forgotPasswordSubmit,
  forgotPasswordComplete,
  registerUser,
  setModalStatus,
  setLoginModalOpen,
  changePassword,
  logoutUser
} from "../../redux/user/user.actions";

import {
  selectLoginStatus,
  selectRegisterStatus,
  selectResetStatus,
  selectLoginModalStatus,
  selectUserFetching,
  selectLoginModalOpen,
  selectUserAuthError,
  selectUserAuthStatus
} from "../../redux/user/user.selectors";

import CloseIcon from "@material-ui/icons/Close";

import Login from "./Login";


import { authStates } from "../../settings";

const useStyles = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  root: {
    padding: theme.spacing(3, 2)
  },
  textNarrowField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  },
  textWideField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 300
  },
  dropDownWide: {
    width: 300,
    margin: theme.spacing(1),
    paddingTop: theme.spacing(1)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  closeButton: {
    float: "right",
    paddingTop: "5px",
    // right: theme.spacing(1),
    // top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
}));




const LoginModal = props => {
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("")

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));



  const handleOpen = type => {
    props.setModalStatus(type);
    props.setLoginModalOpen(true);
  };

  const handleClose = () => {
    props.setLoginModalOpen(false);
  };

  const handleSignIn = data => {

    props.loginUser(data.emailAddress, data.password);
  }

  const handleRegister = data => {

    props.registerUser(data);
  };

  const handleChangePassword = data => {

    props.changePassword(
      data.oldPassword,
      data.newPassword
    );
  }

  const handleForgotPasswordStepOne = data => {
    setForgotPasswordEmail(data.emailAddress);
    props.forgotPasswordSubmit(data.emailAddress);
  }
  const handleForgotPasswordStepTwo = data => {
    props.forgotPasswordComplete(
      forgotPasswordEmail,
      data.newPassword,
      data.code
    );
  }

  return (
    <div>
      <Login
        handleOpenModal={handleOpen}
        isLoggedIn={props.isLoggedIn}
        logOut={props.logoutUser}
      />

      <Dialog
        fullScreen={fullScreen}
        open={props.modalOpen}
        onClose={handleClose}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
      >
        <DialogTitle id="scroll-dialog-title">
          {props.modalStatus}

          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
          {props.errorMessage && (
            <MuiAlert severity="error">{props.errorMessage}</MuiAlert>
          )}
          <Fade in={props.modalOpen}>
            {props.userIsFetching === true ? (
              <DialogContent dividers={true}>
                <Typography>Loading ...</Typography>
              </DialogContent>
            ) : [authStates.LOGIN, authStates.LOGIN_FAILED].includes(
                props.modalStatus
              ) ? (
                
              <SignInForm
                classes={classes}
                Footer={ModelActions}
                footerProps={{
                  modalStatus: props.modalStatus,
                  handleOpen: handleOpen
                }}
                handleOpen={handleOpen}
                onSubmit={handleSignIn}
              />

            ) : [authStates.SIGNUP, authStates.SIGNUP_FAILED].includes(
                props.modalStatus
              ) ? (
              <RegisterForm
                classes={classes}
                Footer={ModelActions}
                footerProps={{
                  modalStatus: props.modalStatus,
                  handleOpen: handleOpen
                }}
                onSubmit={handleRegister}
                handleOpen={handleOpen}
              />
            ) : [
                authStates.FORGOT_PASSWORD,
                authStates.FORGOT_PASSWORD_SUBMIT_FAILED
              ].includes(props.modalStatus) ? (
                <ForgotPasswordStepOneForm
                classes={classes}
                Footer={ModelActions}
                footerProps={{
                  modalStatus: props.modalStatus,
                  handleOpen: handleOpen
                }}
                onSubmit={handleForgotPasswordStepOne}
                handleOpen={handleOpen}
              />
            ) : [
                authStates.FORGOT_PASSWORD_SUBMIT_SUCCESS,
                authStates.FORGOT_PASSWORD_COMPLETED_FAILED
              ].includes(props.modalStatus) ? (
                <ForgotPasswordStepTwoForm
                classes={classes}
                Footer={ModelActions}
                footerProps={{
                  modalStatus: props.modalStatus,
                  handleOpen: handleOpen
                }}
                onSubmit={handleForgotPasswordStepTwo}
                handleOpen={handleOpen}
              />
            ) : [
                authStates.CHANGE_PASSWORD,
                authStates.CHANGE_PASSWORD_FAILED,
                authStates.CHANGE_PASSWORD_REQUIRED
              ].includes(props.modalStatus) ? (
                <ChangePasswordModalForm
                classes={classes}
                Footer={ModelActions}
                footerProps={{
                  modalStatus: props.modalStatus,
                  handleOpen: handleOpen
                }}
                handleOpen={handleOpen}
                onSubmit={handleChangePassword}
              />
            ) : (
              <p></p>
            )}
          </Fade>
         </Dialog>
    </div>
  );
};

const ModelActions = (props) => (
    <DialogActions>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item>
              {[
                authStates.LOGIN,
                authStates.LOGIN_FAILED,
                authStates.SIGNUP,
                authStates.SIGNUP_FAILED
              ].includes(props.modalStatus) && (
                <Button
                  onClick={() => props.handleOpen(authStates.FORGOT_PASSWORD)}
                  color="secondary"
                >
                  Forgot your password?
                </Button>
              )}
            </Grid>
            <Grid item>
                {[
                  authStates.CHANGE_PASSWORD_SUCCESS,
                  authStates.LOGIN_SUCCESS,
                  authStates.SIGNUP_SUCCESS,
                  authStates.FORGOT_PASSWORD_COMPLETED_SUCCESS
                ].includes(props.modalStatus) === false && (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Submit
                  </Button>
                )}
            </Grid>
          </Grid>
        </DialogActions>
     
)

const mapStateToProps = createStructuredSelector({
  loginStatus: selectLoginStatus,
  registerStatus: selectRegisterStatus,
  resetStatus: selectResetStatus,
  modalStatus: selectLoginModalStatus,
  userIsFetching: selectUserFetching,
  modalOpen: selectLoginModalOpen,
  errorMessage: selectUserAuthError,
  isLoggedIn: selectUserAuthStatus
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      loginUser,
      forgotPasswordSubmit,
      forgotPasswordComplete,
      changePassword,
      registerUser,
      setModalStatus,
      setLoginModalOpen,
      changePassword,
      logoutUser
    },
    dispatch
  );

// export default connect(mapStateToProps, mapDispatchToProps)(Shop);
export default connect(mapStateToProps, mapDispatchToProps)(LoginModal);
