import React, { useState, useRef, useEffect } from "react";
import { Hidden,Button } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import { StyledMenu, StyledMenuItem } from "../componentStyles";
import {
  Typography,
  IconButton,
  ListItemText,
  ListItem,
  MenuList,
  Divider,
  Drawer,
  Box
} from "@material-ui/core";
import Search from "./Search";
import LoginModal from './LoginModal';
import { ForEach } from "../ForEach"
import { Link, withRouter } from "react-router-dom";
import "./sidenav.scss";

import { getNavigationStructure } from "../../microSiteConfig"
const navigation = getNavigationStructure()

const useStyles = makeStyles(theme => ({
  list: {
    width: 210,
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(1)
  },
  listItem: {
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1)
  },
  title: {
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
   
  },
  titleContainer: {
    background: "#e6e6e6"
  },
  menuButton: {
    marginLeft: 0,
    color: 'inherit', 
    textDecoration: 'inherit',
    '&:focus': {
      outline: 0
    }
  }
}));

function SideNav(props) {
  const classes = useStyles();


  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = event => {
    setIsOpen(!isOpen)
  };

  const closeNav = event => {
    setIsOpen(false)
  }

  useEffect(() => {
  }, [navigation]);

  return (
    <div>
      <IconButton
        onClick={toggleDrawer}
        edge="start"
        className={classes.menuButton}
        color="inherit"
        aria-label="menu"
      >
        <MenuIcon />
      </IconButton>

      <Drawer open={isOpen} onClose={() => setIsOpen(false)}>
        <div className={classes.list} role="presentation">

        <Hidden smUp>
              <LoginModal />
        </Hidden>
        <ListItem>
          <Search toggle={false} />
        </ListItem>
        <ForEach items={navigation} component={(group) => (
          <div>
            <Box className={classes.titleContainer}>
              
              <Typography className={classes.title}>{group.label}</Typography>
              <Divider/>
            </Box>
            <ForEach items={group.links} component={item => (
              <ListItem className={classes.listItem} button key={item.index} onClick={closeNav} component={props => <Link to={item.path} style={{ color: 'inherit', textDecoration: 'inherit' }}  {...props} />}>
                <ListItemText primary={item.label} />
              </ListItem>
            )}/>
          </div>
        )}/>
      </div>
      </Drawer>
    </div>
  );
}

export default withRouter(SideNav);
