import { getFilters , getPaging, getStaticFilters } from "../../microSiteConfig"

export const INITIAL_STATE = {
    pending: false,
    error: null,
    isFetched: null,
    statics:{
        clearanceFilter:{
            productinstance_clearanceitem: {
                type: "equal",
                value: true
            }
        },
    },
    query: {
        statics: getStaticFilters()
    },
    product: [], // initial product from API
    metadata: {
        offset: 0,
        limit: 12,
        total: null,
        orderby: 0,
        search: "",
        count: 0
    },
    // the sort section of the query
    pagination: getPaging(),
    filters: getFilters()
};