import * as mutation from "./cart.mutations";
import * as Product from "../../services/product";
import {
  getCouponList,
  getSingleCoupon,
  redeemCoupon
} from "../../services/order";

import { notify } from "../app/app.actions";
import reduxHistory from "../browserHistory";

const handleError = e => {
  console.log("CART ACTION ERROR");
  console.log(e);
};

export const removeItem = data => async dispatch => {
  dispatch(mutation.removeItem(data));
};

export const addItemToLab = (data, hideNotify = false, preventNotifyDups = false) => async dispatch => {
  if(!hideNotify){
    dispatch(notify("Updated cart",'success',preventNotifyDups));
  }
  dispatch(mutation.addItemToLab(data));
  if(data.customstockmessagenotify){
    dispatch(notify(data.customstockmessagenotify, 'warning'));

  }
};

export const minusItemToLab = (data, hideNotify = false, preventNotifyDups = false) => async dispatch => {
  if(!hideNotify){
    dispatch(notify("Updated cart",'success',preventNotifyDups))
  }
  dispatch(mutation.minusItemToLab(data));
};

export const addItem = (data, hideNotify = false, preventNotifyDups = false) => async dispatch => {
  dispatch(mutation.addItem(data))
  
  if(data.customstockmessagenotify){
    dispatch(notify(data.customstockmessagenotify, 'warning'));

  } else if(!hideNotify){
      dispatch(notify("Updated cart",'success',preventNotifyDups));
  }

};


export const reAddItem = (data) => async dispatch => {
  dispatch(notify("Adding order contents to cart"));
  dispatch(mutation.isFetchingData());
   var prodids = [];
    Object.keys(data.items).map(function(key, index) {
      prodids.push(data.items[key].data.productid);
    });
    let thisQuery = {
      attributes: {
      productid: {
          type: "in",
          value: prodids
      }
      },
      sort: {},
      limit: {}
    }
  await Product.queryProduct(thisQuery)
  .then(payload => {
      dispatch(mutation.reAddItem(data,payload.data));
  })
  .catch(error => {
    handleError(error)
    //dispatch(mutation.fetchProductsError(err));
    //dispatch(mutation.fetchCouponError(error));
  })
  .finally(() => {
    reduxHistory.push("/cart");
    dispatch(mutation.notFetchingData());
  });

};

export const minusItem = (data, hideNotify = false, preventNotifyDups = false) => async dispatch => {
  if(!hideNotify){
    dispatch(notify("Updated cart",'success',preventNotifyDups));
  }
  dispatch(mutation.minusItem(data));
};



export const setJobNum = (item, jobNum) => dispatch => {
  dispatch(mutation.setJobNum(item, jobNum));
};

export const setReference = (item, reference) => dispatch => {
  dispatch(mutation.setReference(item, reference));
};

export const setStoreDirectDetialReferene = ref => dispatch => {
  dispatch(mutation.setStoreDirectDetialReferene(ref));
};
export const setStoreDirectDetailComments = comments => dispatch => {
  dispatch(mutation.setStoreDirectDetailComments(comments));
};

export const setLabDirectKey = value => dispatch => {
  dispatch(mutation.setLabDirectKey(value));
};
export const setLabDirectValue = value => dispatch => {
  dispatch(mutation.setLabDirectValue(value));
};

export const clearCart = () => async dispatch => {
  dispatch(mutation.clearCart());
};
//stash version below
export const updateCartItemLabQuantity = (item,quantity, hideNotify = false, preventNotifyDups = false ) => dispatch => {
  dispatch(mutation.updateCartItemLabQuantity(item,quantity))
  if(item.customstockmessagenotify){
    dispatch(notify(item.customstockmessagenotify, 'warning'));
  } else if(!hideNotify){
    dispatch(notify("Updated cart",'success',preventNotifyDups));
  }
}

export const setCartItemLabDirectEnabled = (item,enabled) => async dispatch => {
  dispatch(mutation.labDirectEnabled(item,enabled))
}

export const setCoupon = code => async dispatch => {
  dispatch(mutation.isFetchingData());
  getSingleCoupon(code)
    .then(coupon => {
      dispatch(mutation.fetchCouponSuccess(coupon));
    })
    .catch(error => {
      dispatch(mutation.fetchCouponError(error));
    })
    .finally(() => {
      dispatch(mutation.notFetchingData());
    });
};

export const setChosenCourier = courier => dispatch => {
  dispatch(mutation.setSingleCourier(courier));
};

export const updateQuantity = (item, quantity, hideNotify = false, preventNotifyDups = false ) => dispatch => {
  dispatch(mutation.updateQuantity(item, quantity));
  if(item.customstockmessagenotify){
    dispatch(notify(item.customstockmessagenotify, 'warning'));
  } else if(!hideNotify){
    dispatch(notify("Updated cart",'success',preventNotifyDups));
  }
};
// export const updateQuantity = (item,dispatch) => bindActionCreators(mutation.updateQuantity(item),dispatch);

export const setShippingOption = option => dispatch => {
  dispatch(mutation.setShippingOption(option));
};

export const setLabDirectState = courier => dispatch => {
  dispatch(mutation.setLabDirectState(courier));
};

export const setStoreDirectState = data => dispatch => {
  dispatch(mutation.setStoreDirectState(data));
}

