// @ts-check

import React, { useState } from "react";
import { withRouter, Link } from "react-router-dom";

import "./cartpage.styles.scss";

import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";

import {
  Select,
  Typography,
  Grid,
  TextField,
  useTheme,
  FormControl,
  InputLabel,
  MenuItem,
  Checkbox,
  FormHelperText
} from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
import { Alert, AlertTitle } from "@material-ui/lab";

import {
  SITE_TYPES,
  getSiteType,
  getNavigationPaths
} from "../../microSiteConfig";



import OrderSummary from "./OrderSummary";
import CustomButton from "../../components/custom-button/custom-button.component";
import AddressBookItem from "../../components/accountForms/AddressBockItem.component"
import { AdjustableTable } from "../../components/adjustable-table/AdjustableTable";
import { CartItemValidation } from "../../components/checkout-item/CheckoutItem.component";

import { 
  AddressForm,
  mapOutput,
  AddressFormBody,
  AddressValidation
} from "../../components/accountForms";
import HiddenIf from "../../components/HiddenIf";

import {
  setCoupon,
  setLabDirectState,
  setStoreDirectState
} from "../../redux/cart/cart.actions";
import {
  selectCartSubTotal,
  selectCartTotal,
  selectCartDiscount,
  selectCartTax,
  selectStandardShippingFee,
  selectExpressShippingFee,
  selectTotalWeight,
  selectCoupon,
  selectShippingFee,
  selectCheckoutItemLabDirectQuantity,
  selectCheckoutItemStoreDirectQuantity,
  selectStoreDirectDetails,
  selectLabDirectDetails,
  selectOrderObject,
  selectCartItems,
  selectCartItemKeys
} from "../../redux/cart/cart.selectors";
import {
  selectShippingInfo,
  selectCourier,
  selectUserAuthStatus,
  selectUserData,
  selectBillingInfo
} from "../../redux/user/user.selectors";
import { updateUserData, resetUserData } from "../../redux/user/user.actions";

import { constructOrders } from "../../redux/user/user.actions";
import { bindActionCreators } from "redux";
import { properRounding, errorMessageSummaryToArray } from "../../utils/helpers";

import { useForm, Controller, useFieldArray, FormContext } from 'react-hook-form';
import * as yup from 'yup';
import { labDirectEnabled } from "../../redux/cart/cart.mutations";

const ENDPOINTS = getNavigationPaths();


const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 400
  },
  form: {
    width: "100%"
  },
  root: {
    padding: theme.spacing(3, 2)
  },
  lab: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center"
  },
  inputItem: {
    marginBottom: "10px",
    padding: "10px",
    textAlign: "center",
    width: "100%"
  },
  couponForm: {
    marginBottom: theme.spacing(2),
    width: "100%"
  },
  selectContainer: {
    width: "100%",
    margin: theme.spacing(3, 0)
  },
  couponGrid: {
    flexGrow: 1,
    padding: theme.spacing(3, 2)
  },
  page: {
    paddingTop: theme.spacing(6),
    height: "71vh",
    minHeight: "230px"
  },
  textBox: {
    paddingLeft: "5%",
    paddingRight: "5%",
    [theme.breakpoints.up("sm")]: {
      paddingLeft: "5%",
      paddingRight: "5%"
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: "10%",
      paddingRight: "10%"
    },
    [theme.breakpoints.up("lg")]: {
      paddingLeft: "10%",
      paddingRight: "10%"
    }
  },
  textTitle: {
    fontSize: 25,
    [theme.breakpoints.up("sm")]: {
      fontSize: 30
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 40
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: 40
    }
  },
  textBody: {
    fontSize: 16,
    [theme.breakpoints.up("sm")]: {
      fontSize: 18
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 20
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: 24
    }
  },
  selectItem: {
    display: "flex",
    justifyContent: "space-between"
  }
}));

const EmptyCartPage = props => (
  <Grid container className={props.classes.page}>
    <Grid item xs={12} className={props.classes.textBox}>
      <Typography variant="h4" className={props.classes.textTitle}>
        Your cart is currently empty.
      </Typography>
      <br />
      <Typography variant="h6" className={props.classes.textBody}>
        You can add items to you cart from either the{" "}
        <Link to="/shop">Shop Page</Link> or the{" "}
        <Link to="/clearance">Clearance Page</Link>.
      </Typography>
    </Grid>
  </Grid>
);

export const attr = {
  labdirect: {key: "labdirect[]", label: "Lab Direct"},
  labdirectlab: {key: "labdirectlab", label: "Laboratory"},
  labdirectregion: {key: "labdirectregion", label: "Lab Direct Region"},
  storedirectref: {key: "storedirectref", label: "Store Direct Reference"},
  storedirectcomments: {key: "storedirectcomments", label: "Store Direct Comments"},
  shippingmethod: {key: "shippingmethod", label: "Shipping Method"},
  coupon: {key: "coupon", label: "Coupon Code"},
}

export const Validation = yup.object().shape({
  formcart: yup.array().of(CartItemValidation),
  [attr.labdirect.key]: yup.boolean().label(attr.labdirect.label),
  [attr.labdirectlab.key]: yup.string().when('formcart',(formcart,schema) => {
    for(var i = 0, k=formcart.length; i<k; i++){
      if(formcart[i].labdirect){
      return schema.required();
      }
    }
  }
  ).label(attr.labdirectlab.label),
  [attr.labdirectregion.key]: yup.string().when('formcart',(formcart,schema) => {
        for(var i = 0, k=formcart.length; i<k; i++){
          if(formcart[i].labdirect){
          return schema.required();
          }
        }
      }
      ).label(attr.labdirectregion.label),
  [attr.storedirectref.key]: yup.string().label(attr.storedirectref.label),
  [attr.storedirectcomments.key]: yup.string().label(attr.storedirectcomments.label),
  billingAddress : AddressValidation,
  shippingAddress : AddressValidation,
 // [attr.shippingmethod.key]: yup.string().required().label(attr.shippingmethod.label),
  [attr.coupon.key]: yup.string().label(attr.coupon.label)
});

const LabDirectInfo = props => {
  const handelStateSelect = event => {
    props.setLabDirectState({
      ...props.state,
      state: event.target.value,
      region: ""
    });
    return event.target.value;
  };
  const handelRegionSelect = event => {
    props.setLabDirectState({
      ...props.state,
      region: event.target.value
    });
    return event.target.value;
  };

  var findState = 0;
  if(props.state.state && props.state.state.length){
    Object.keys(props.options).map(k => (
      findState+=(k===props.state.state?1:0)
    ))
    if(!findState){
      props.setLabDirectState({
        ...props.state,
        state: "",
        region: ""
      });
    }
}

  const errs = props.validation.errors
  const lab = props.validation.watch(attr.labdirectlab.key)
  const region = props.validation.watch(attr.labdirectregion.key)
  // check to ensure that the region is valid for the given lab, if not set region to ""
  if (typeof lab != "undefined" && typeof region != "undefined" && region != "") {
    const validRegions = props.options[lab].map((value) => { return value["region"] })
    if (!validRegions.includes(region)) {
      props.validation.setValue(attr.labdirectregion.key, "")
    }
  }

  return (
    <Grid
      container
      justify="space-between"
      className={props.classes.couponGrid}
    >
      <Grid item xs={12} md={12} lg={12}>
        <div className="direct-detail-page">
          <h2 className="title-center">Lab Direct Details</h2>
        </div>
        <Typography variant="body1" align="center">
          All Lab Direct orders will be shipped direct to your nominated lab.
        </Typography>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6} lg={6}>
        <FormControl className={props.classes.form} error={typeof errs[attr.labdirectlab.key] !== "undefined"}>
            <InputLabel htmlFor="lab">Select Laboratory</InputLabel>
            <Select
              value={props.state.state}
              inputProps={{min: 0, style: { textAlign: 'center' },  value: props.state.state}}
              className={props.classes.selectContainer}
              onChange={handelStateSelect}
            >
              {Object.keys(props.options).map(k => (
                <MenuItem key={k} value={k}>
                  {k}
                </MenuItem>
              ))}
              </Select>
            <TextField
                inputProps={{min: 0, style: { textAlign: 'center' }, value: props.state.state, type: 'hidden'}}
                placeholder={attr.labdirectlab.label}
                variant="outlined"
                name={`${attr.labdirectlab.key}`}
                inputRef={props.validation.register}
              />
            {(typeof errs[attr.labdirectlab.key] == "undefined") ? null : 
            <FormHelperText>{errs[attr.labdirectlab.key].message}</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
        <FormControl className={props.classes.form} error={typeof errs[attr.labdirectregion.key] !== "undefined"}>
            <InputLabel>Region</InputLabel>
            <Select
              value={props.state.region}
              className={props.classes.selectContainer}
              onChange={handelRegionSelect}
              inputProps={{min: 0, style: { textAlign: 'center' }, value: props.state.region}}
            >
              {props.state.state
                ? props.options[props.state.state].map((option, id) => (
                    <MenuItem key={option.region} value={option.region}>
                      {option.region}
                    </MenuItem>
                  ))
                : null}
            </Select>
            <TextField
                inputProps={{min: 0, style: { textAlign: 'center' }, value: props.state.region, type: 'hidden'}}
                placeholder={attr.labdirectregion.label}
                variant="outlined"
                name={`${attr.labdirectregion.key}`}
                inputRef={props.validation.register}
            />
            {(typeof errs[attr.labdirectregion.key] == "undefined") ? null : 
            <FormHelperText>{errs[attr.labdirectregion.key].message}</FormHelperText>}
          </FormControl>
        </Grid>
      </Grid>
    </Grid>
  );
};

const StoreDirectInfo = props => {

  const handelStoreDirectReference = event => {
    props.setStoreDirectState({
      ...props.storeDirect,
      reference: event.target.value
    });
  };
  const handelStoreDirectComments = event => {
    props.setStoreDirectState({
      ...props.storeDirect,
      comments: event.target.value
    });
  };

  const errs = props.validation.errors
  return (
    <div className="direct-detail-page">
      <h2 className="title-center">Store Direct Details</h2>
      <Grid
        container
        justify="space-between"
        className={props.classes.couponGrid}
        spacing={2}
      >
        <Grid item xs={12} md={6} lg={6}>
          <HiddenIf value={errs && typeof errs[attr.storedirectref.key] !== "undefined" }>
            <Typography variant="subtitle2" style={{color: "red", fontSize: "15px"}}>
                {(errs ? errs[attr.storedirectref.key] || {} : {}).message}
              </Typography>
          </HiddenIf>
          <TextField
            className={props.classes.inputItem}
            placeholder="Reference"
            name={attr.storedirectref.key}
            inputRef={props.validation.register}
            variant="outlined"
            inputProps={{min: 0, style: { textAlign: 'center' }}}
            value={props.storeDirect.reference}
            onChange={handelStoreDirectReference}
          />
        </Grid>
        <Grid item className={props.classes.inputItem} xs={12} md={6} lg={6}>
          <TextField
            placeholder="Comments"
            className={props.classes.inputItem}
            name={attr.storedirectcomments.key}
            inputRef={props.validation.register}
            variant="outlined"
            inputProps={{min: 0, style: { textAlign: 'center' }}}
            value={props.storeDirect.comments}
            onChange={handelStoreDirectComments}
          />
        </Grid>
      </Grid>
    </div>
  );
};

// const AddressInfoNew = props => {
//   const handleShipToBillingAddress = event => {
//     props.setStoreDirectState({
//       ...props.storeDirect,
//       shippingToBillingAddress: event.target.checked
//     });
//   };
//   const updateUserAddress = address => location => event => {
//     props.updateUserData([address, location], event.target.value);
//     event.preventDefault();
//   };

//   return (
//     <div className="user-address-page">
//       <h2 className="title-center">Store Direct Delivery Details</h2>
//       <div className="userinfo-group">
//         <AddressForm
//           title="Billing Information"
//           display={false}
//           saveAble={false}
//           data={props.billingAddress}
//           resetUser={props.resetUserData}
//           updateAddressData={location => event =>
//             updateUserAddress("billingAddress")(location)(event)}
//           register = {props.register}
//         />
//         <AddressForm
//           title="Shipping Information"
//           data={
//             props.storeDirect.shippingToBillingAddress
//               ? props.billingAddress
//               : props.shippingAddress
//           }
//           display={false}
//           saveAble={false}
//           disabled={props.storeDirect.shippingToBillingAddress}
//           resetUser={props.resetUserData}
//           updateAddressData={location => event =>
//             updateUserAddress("shippingAddress")(location)(event)}
//         />
//       </div>
//       <div className="ship-info">
//         <label>
//           <Checkbox
//             checked={props.storeDirect.shippingToBillingAddress}
//             onChange={handleShipToBillingAddress}
//             value="shipAddress"
//           />
//           Ship To Billing Address
//         </label>
//       </div>
//     </div>
//   );
// };

const AddressInfo = ({validation, ...props}) => {
  const handleShipToBillingAddress = event => {
    props.setStoreDirectState({
      ...props.storeDirect,
      shippingToBillingAddress: event.target.checked
    });
  };

  const updateUserAddress = address => location => event => {
    props.updateUserData([address, location], event.target.value);
    event.preventDefault();
  };
  return (
    <div className="user-address-page">
      <h2 className="title-center">Store Direct Delivery Details</h2>
      <div className="userinfo-group">
        <AddressFormBody
          title = "Billing Information"
          display={false}
          saveAble={false}
          data={props.billingAddress}
          validation={validation}
          prefix="billingAddress"
          cartUpdateAddress={location => event =>
            updateUserAddress("billingAddress")(location)(event)}
          isCart={true}
        />


        <AddressFormBody
          title="Shipping Information"
          data={
            props.storeDirect.shippingToBillingAddress
              ? props.billingAddress
              : props.shippingAddress
          }
          display={false}
          saveAble={false}
          isCart={true}
          disabled={props.storeDirect.shippingToBillingAddress}
          useBillingAddress={props.storeDirect.shippingToBillingAddress}
          validation={validation}
          prefix="shippingAddress"
          cartUpdateAddress={location => event =>
            updateUserAddress("shippingAddress")(location)(event)}
        />
      </div>
      <div className="ship-info">
        <label>
          <Checkbox
            checked={props.storeDirect.shippingToBillingAddress}
            onChange={handleShipToBillingAddress}
            value="shipAddress"
          />
          Ship To Billing Address
        </label>
      </div>
    </div>
  );
};

const ShippingInfo = props => {
  const displayCourierValue = () => {

    if(props.courier && props.courier.length){
      var courLow = props.courier.toLowerCase();
      if(courLow.indexOf("ocs") >=0){
        return "OCS"
      }
      else if(courLow.indexOf("nod") >=0 || courLow.indexOf("n.o.d") >=0){
        return "NOD"
      } else {
        return props.courier;
      }
    } else {
      return "";
    }
  };
  const handleShippingOption = event => {
    props.setStoreDirectState({
      ...props.storeDirect,
      shippingInfo: event.target.value
    });
  };
  return (
    <>
      <Typography align="center" className={props.classes.textBody}>
        Shipping Method
      </Typography>
      <FormControl className={props.classes.form}>
        {props.storeDirect.shippingInfo ? (
          <InputLabel htmlFor="courier">{displayCourierValue()}</InputLabel>
        ) : (
          null
        )}
        <Select
          value={props.storeDirect.shippingInfo}
          className={props.classes.selectContainer}
          onChange={handleShippingOption}
        >
          <MenuItem value="Standard">
            Standard - ${properRounding(props.standardShippingFee,2,1)}
          </MenuItem>
          {
          /*getSiteType() === SITE_TYPES.AU ? (
            <MenuItem value="Express">
              Express - ${properRounding(props.expressShippingFee,2,1)}
            </MenuItem>
          ) : (
            null
          )
          */}
        </Select>
      </FormControl>
    </>
  );
};

const CouponInfo = (props) => {
  const code = props.validation.watch(attr.coupon.key)
  const handleSubmit = () => {
    console.log("setting coupon code to: ", code)
    props.setCoupon(code)
  }
  return (
  <>
    <TextField 
      className={props.classes.couponForm}
      label="Coupon Code"
      variant="filled"
      name={attr.coupon.key}
      inputRef={props.validation.register}
      defaultValue=""
    />
    <CustomButton variant="contained" onClick={handleSubmit}>
      Apply Coupon
    </CustomButton>

    {/* <HiddenIf value={typeof code !== "undefined" && code != ""}>
      <Alert severity="success">
        <AlertTitle>Success</AlertTitle>
        Code Applied
      </Alert>
    </HiddenIf>
    <HiddenIf value={typeof code !== "undefined" && code != ""}>
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        Code not valid
      </Alert>
    </HiddenIf> */}
  </>
)};

const refreshForm = form => {
  // refresh the form state to cause a rerender
  // form.setError("refresh")
}

const CartPage = props => {
  
  const classes = useStyles();

  const form = useForm({validationSchema: Validation, mode:"onBlur"});
  const formCart = useFieldArray({control: form.control, name: "formcart"});

  if (!props.labDirect.enabled) {
    if (form.errors[attr.labdirectlab.key]) form.clearError(attr.labdirectlab.key)
    if (form.errors[attr.labdirectregion.key]) form.clearError(attr.labdirectregion.key)
  }

  const printstate = false
  if (printstate) {
    console.log("~~~~~~~ form data ~~~~~~~")
    console.log(JSON.stringify(form.getValues()));
    console.log("~~~~~~~end of data ~~~~~~~")
    console.log('form is valid: ', form.formState.isValid)
    console.log("========= errors ========")
    console.log(JSON.stringify(form.errors))
    console.log("========= end errors ========")
    console.log("full form state: ", JSON.stringify(form.formState))
  }

  const onSubmit = data => {
    console.log("========= submitting form ==========")
    console.log(JSON.stringify(data));
    console.log("========= end form data ==========")
  }

  // const [labDirectKey, setLabDirectKey] = useState("");
  // const [labDirectValue, setLabDirectValue] = useState("");
  // const [singleCourier, setSingleCourier] = useState("");
  // const [shippingOption, setSingleShippingOption] = useState("");

  const handleLabOptionSelectChange = event => {
    //link to redux
    props.setLabDirectValue(event.target.value);
  };

  // const handlePlaceOrder = () => {
  //   let payload = props.orderObject;
  //   console.log("Payload: " + JSON.stringify(payload));
  //   props.placeOrder(payload);
  //   props.history.push(ENDPOINTS.order);
  // };
  const handlePlaceOrder = () => {

  //delete any empty items
  Object.keys(props.cartItems).map(function(key, index) {
    if(!props.cartItems[key].state.quantity){
      delete(props.cartItems[key]);
    }
  });

    const payload = {
      billingAddress: props.userData.billingAddress,
      shippingAddress: props.userData.shippingAddress,
      shippingToBillingAddress: props.storeDirect.shippingToBillingAddress,
      recipients: [props.userData.email],
      order: {
        id: "",
        date: "",
        subtotal: properRounding(props.subTotal,2,1),
        shipping: properRounding(props.shippingFee,2,1),
        tax: properRounding(props.tax,2,1),
        grandtotal: properRounding(Number(
          props.subTotal + props.shippingFee + props.tax - props.discount
        ),2,1),
        shippingMethod:
          props.userData.courier + " " + props.storeDirect.shippingInfo,
        labDirectKey: props.labDirect.state.state,
        labDirectValue: props.labDirect.state.region,
        storeDirectRef: props.storeDirect.reference,
        storeDirectComments: props.storeDirect.comments
      },
      details: {
        labDirectQuantity: props.labDirectQuantity,
        storeDirectQuantity: props.storeDirectQuantity
      },
      items: props.cartItems,
      UserId: props.userData.email
    };
    console.log("Payload:" + JSON.stringify(payload));
    props.constructOrders(payload);
    props.history.push(ENDPOINTS.order);
  };


  const hendleCheckboxChange = event => {
    props.setShippingCheckbox(event.target.checked);
  };

  return (
    <FormContext {...form} >
    <form>
      <HiddenIf value={props.cartItemKeys.length === 0}>
        <EmptyCartPage classes={classes} />
      </HiddenIf>
      <HiddenIf value={props.cartItemKeys.length > 0}>
        <div className="cart-and-review">
          <AdjustableTable
            headerTitles={[
              "Image",
              "Name",
              "Price",
              "Qty",
              "Subtotal",
              "Remove"
            ]}
            rowItems={props.cartItemKeys}
            subTotal={props.subTotal}
            validation={form}
            formCart={formCart}
            isCartPage
          />
          <HiddenIf value={props.labDirect.enabled}>
            <LabDirectInfo
              classes={classes}
              setLabDirectState={props.setLabDirectState}
              validation={form}
              {...props.labDirect}
            />
          </HiddenIf>
          <StoreDirectInfo
            classes={classes}
            setStoreDirectState={props.setStoreDirectState}
            storeDirect={props.storeDirect}
            validation={form}
            {...props}
          />
          <AddressInfo
            validation={form}
            billingAddress={props.billingAddress}
            shippingAddress={props.shippingAddress}
            storeDirect={props.storeDirect}
            setStoreDirectState={props.setStoreDirectState}
            updateUserData={props.updateUserData}
            resetUserData={props.resetUserData}
          />

          <Grid
            container
            justify="space-between"
            className={classes.couponGrid}
            spacing={2}
          >
            <Grid item xs={12} md={12} lg={12}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6} lg={6}>
                  <ShippingInfo
                    classes={classes}
                    courier={props.courier}
                    standardShippingFee={props.standardShippingFee}
                    expressShippingFee={props.expressShippingFee}
                    storeDirect={props.storeDirect}
                    setStoreDirectState={props.setStoreDirectState}
                    validation={form}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <CouponInfo
                    classes={classes}
                    validation={form}
                    setCoupon={props.setCoupon}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <div className="review-page">
            <h2 className="review-header">Review Your Order</h2>
            <OrderSummary
              shippingAddress={props.shippingAddress}
              billingAddress={props.billingAddress}
              shippingFee={props.shippingFee}
              totalWeight={props.totalWeight}
              coupon={props.coupon.data}
              rows={props.cartItems}
              subTotal={props.subTotal}
              tax={props.tax}
              discount={props.discount}
              total={props.total}
              storeDirectDetailRef={props.storeDirect.reference}
              storeDirectDetailComments={props.storeDirect.comments}
              labDirect={props.labDirect.state}
              labDirectQuantity={props.labDirectQuantity}
              storeDirectQuantity={props.storeDirectQuantity}
              shippingCheckbox={props.storeDirect.shippingToBillingAddress}
            />
{console.log("form.errors",form.errors)}
{form.errors?

    <ul style={{color:'red'}}>
{errorMessageSummaryToArray(form.errors).map((item,index) => (
<li key={item+index}>{item}</li>
))}
   
  </ul>
:null}
            

            {props.total?
            <CustomButton
              disabled={!props.isLoggedIn}
              onClick={(event) => {
                event.preventDefault()
                form.handleSubmit(data => {
                  //onSubmit(data)
                  handlePlaceOrder();
                })()
              }}
              fixedWidth
              checkout={true}
            >
              Place Order Now
            </CustomButton>
            :<Typography style={{margin: "20px 0", fontWeight: '700', textAlign:'center'}}>Cart must have items to be able to place order</Typography>}
          </div>
        </div>
      </HiddenIf>
    </form>
    </FormContext>
  );
};

const mapStateToProps = createStructuredSelector({
  shippingAddress: selectShippingInfo,
  billingAddress: selectBillingInfo,
  cartItemKeys: selectCartItemKeys,
  cartItems: selectCartItems,
  courier: selectCourier,
  shippingFee: selectShippingFee,
  expressShippingFee: selectExpressShippingFee,
  standardShippingFee: selectStandardShippingFee,
  totalWeight: selectTotalWeight,
  total: selectCartTotal,
  subTotal: selectCartSubTotal,
  discount: selectCartDiscount,
  tax: selectCartTax,
  coupon: selectCoupon,
  isLoggedIn: selectUserAuthStatus,
  userData: selectUserData,
  labDirectQuantity: selectCheckoutItemLabDirectQuantity,
  storeDirectQuantity: selectCheckoutItemStoreDirectQuantity,
  storeDirect: selectStoreDirectDetails,
  labDirect: selectLabDirectDetails,
  orderObject: selectOrderObject
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      constructOrders,
      setCoupon,
      setLabDirectState,
      setStoreDirectState,
      updateUserData,
      resetUserData,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CartPage)
);