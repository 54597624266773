import { matchPath } from "react-router-dom"

export const checkStaticQuery = (query) => {

}


export const getQuery = (filter, order, limit) => {
    return {
        data: {
            attributes: filter,
            sort: order,
            limit: limit,
        },
        metadata: {}
    }
}//getQuery construct the query object when the product state change

// will take the current filter and add the new item to its state
// add query event
const filterItemType = {
    type: 'in',
    value: 'red',
    key: 'productinstance_color'
}
const currentFilterType = {
    productinstance_color: {
        type: 'in',
        value: [
            'blue',
            'green',
            'red'
        ]
    }
}

export const mapQueryToFilter = (query,filter,meta) => {
    Object.keys(query).map(key => {
        const value = query[key]
        switch(query[key].type){
            case "in":{
                value.value.map(itemKey => {
                    filter[key].filter[itemKey].value = true
                })
                break;
            }
            case "between": {
                filter[key].filter.upper.value = value.value.upper
                filter[key].filter.lower.value = value.value.lower
                break;
            }
            case "search": {
                meta.search = value.value
                break;
            }
        }
    })
    return {
        filter,
        meta
    }
}

export const applyUserBrandDiscounts = (stateUserData, stateProductsData, singleProductView = 0) => {

    /*
    "AURA": {label: "Aura Eyewear",value: false},
    "AURFL": {label: "Aura Flex",value: false},
    "CON": {label: "Convertibles Eyewear",value: false},
    "CONTI": {label: "Convertibles Titanium",value: false},
    "GM": {label: "Gemini Collection",value: false},
    "GE": {label: "Gemini Essentials",value: false},
    "MAD": {label: "Mad in Italy",value: false},
    "MBE": {label: "Mersey Beat Eyewear",value: false},
    "PAP": {label: "Paparazzi",value: false},
    "E": {label: "Stepper Eclectic",value: false},
    "FU": {label: "Stepper Fusion",value: false},
    "FUA": {label: "Stepper Fusion Asian",value: false},
    "SI": {label: "Stepper SI",value: false},
    "STS": {label: "Stepper STS",value: false},
    "ZEI": {label: "Zeiss",value: false},
    "ARZ": {label: "Zeiss Architekt",value: false}
    */
   var discOpts = {
       aura:['AURA','AURFL'],
       convertibles:['CON','CONTI'],
       'gemini collection':['GM'],
       'gemini essentials':['GE'],
       'mersey beat eyewear':['MBE'],
       paparazzi:['PAP'],
       stepper:['SI','STS'],//,'E','FU','FUA'
       zeiss:['ZEI']
    };

    var brands = null;

    if(singleProductView){

        brands = stateUserData;

    } else {

        if(stateUserData.user && stateUserData.user.data.brands){
            brands = stateUserData.user.data.brands;
        }

    }
    if(brands){

        Object.keys(brands).map(function(key, index) {

            if(brands[key].discount && brands[key].discount > 0){

                if(discOpts[key.toLowerCase()]){
                    for(var i = 0, k = discOpts[key.toLowerCase()].length; i < k; i++){

                        Object.keys(stateProductsData).map(function(key2, index2) {
                            if(stateProductsData[key2].subbrand == discOpts[key.toLowerCase()][i]){
                                for(var j = 0, m = stateProductsData[key2].productinstance.length; j< m; j++){


                                    if(stateProductsData[key2].productinstance[j].priceclearanceaud || stateProductsData[key2].productinstance[j].priceclearancenzd){
                                        //no discount as is clearance
                                        
                                    } else {
                                        stateProductsData[key2].productinstance[j].price = (stateProductsData[key2].productinstance[j].price/100 * (100 - brands[key].discount));
                                        stateProductsData[key2].productinstance[j].normalprice = (stateProductsData[key2].productinstance[j].normalprice/100 * (100 - brands[key].discount));
                                        stateProductsData[key2].productinstance[j].discountnote = 'Your '+brands[key].discount+'% discount has been applied';
                                    }

                                    
                                }

                            }

                        });


                    }

                }

            }

        });

    }

    return stateProductsData;
}



// const inFilterHandler = (oldValues,update,filter) => {
//     let newValues = {...oldValues}
//     if(update.group in newValues){
//         let selected = newValues[update.group].value
//         if(selected.includes(update.key) && filter.value === false && selected.length === 1){ 
//             //if gender only select male then click again delete the category in the filter
//             delete newValues[update.group]
//         }else if(selected.includes(update.key) && filter.value === false){
//             // if gender has male female and so on only delete the one that use click
//             selected.splice(selected.indexOf(update.key),1);
//         }else if(filter.value){
//             // else add the value user has clicked in the category
//             selected.push(update.key)
//         }
//     }else if (filter.value === true){
//         //if the category if not exist, then add the new category and value
//         newValues[update.group] = {
//             type: 'in',
//             value: [update.key]
//         }
//     }
//     return newValues
// }
// const betweenFilterHandler = (oldValues,update, filter) => {
//     let newValues = {...oldValues}
//     newValues[update.group] = {
//         type: 'between',
//         value: {
//             upper: filter.upper.value,
//             lower: filter.lower.value
//         }
//     }
//     return newValues
// }
// export const updateFilter = (oldValues,update,filter) => {    
//     switch(update.type){
//         case "in":
//             return inFilterHandler(oldValues,update,filter[update.group]['filter'][update.key])
//         case "between":
//             return betweenFilterHandler(oldValues,update,filter[update.group]['filter'])
//         default: 
//             console.error("Unknown Filter Group Type ",update.type)
//             return oldValues
//     }
    
// }