import { Api } from "./aws/request";
import { getUserEmail, getUser } from "./aws/auth";

export const getApp = () => {
  return Api.getAppConfig();
};

export const getSingleCoupon = code => {
  return Api.getRequest(`/coupons/check/${code}`);
};

export const createOrder = order => {
  return Api.postRequest("/orders/order", order);
};

export const getOrder = (id) => {
  return Api.getRequest(`/orders/order/${id}`)
}

export const getPastOrders = async () => {
  const userEmail = await getUserEmail()
  return Api.getRequest(`/orders/order/${userEmail}`, {
    "Content-Type": "application/json"
  })
}

export const Order = {
  getApp,
  getSingleCoupon,
  getOrder,
  getPastOrders,
  createOrder
};
