


export const addItemToWishList = (wishList, item) =>  {
    if(item.productid in wishList) {
        delete wishList[item.productid];
        return {...wishList};
    }
    wishList[item.productid] = item;    
    return {...wishList};
}

export const removeFromWishList = (wishList, item) => {
    if(item.productid in wishList) { 
        delete wishList[item.productid]; 
        return {...wishList};
    }
    return wishList;
}

export const updateWishlistVals = (theItems, checkItems) => {

    var isFound = false;
    Object.keys(theItems).map(function(key, index) {
        isFound = false;
        for(var i = 0, k=checkItems.length; i<k; i++){
            
            if(checkItems[i].productid === theItems[key].productid){

                for(var j = 0, m=checkItems[i].productinstance.length; j<m; j++){

                    if(checkItems[i].productinstance[j].productinstanceid===theItems[key].code){

                        //update important values to match latest from server
                        theItems[key].price = checkItems[i].productinstance[j].price;
                        theItems[key].normalprice = checkItems[i].productinstance[j].normalprice;
                        theItems[key].priceaud = checkItems[i].productinstance[j].priceaud;
                        theItems[key].pricenzd = checkItems[i].productinstance[j].pricenzd;
                        theItems[key].priceclearanceaud = checkItems[i].productinstance[j].priceclearanceaud;
                        theItems[key].clearanceitem = checkItems[i].productinstance[j].clearanceitem;
                        theItems[key].priceclearancenzd = checkItems[i].productinstance[j].priceclearancenzd;

                        isFound = true;
                        break;
                        
                    }
                }

                if(isFound){
                    break;
                }
            }
        }


    });
    return theItems;
}

export const applyUserBrandDiscountsUser = (stateUserData, stateProductsData, singleProductView = 0) => {
    /*
    "AURA": {label: "Aura Eyewear",value: false},
    "AURFL": {label: "Aura Flex",value: false},
    "CON": {label: "Convertibles Eyewear",value: false},
    "CONTI": {label: "Convertibles Titanium",value: false},
    "GM": {label: "Gemini Collection",value: false},
    "GE": {label: "Gemini Essentials",value: false},
    "MAD": {label: "Mad in Italy",value: false},
    "MBE": {label: "Mersey Beat Eyewear",value: false},
    "PAP": {label: "Paparazzi",value: false},
    "E": {label: "Stepper Eclectic",value: false},
    "FU": {label: "Stepper Fusion",value: false},
    "FUA": {label: "Stepper Fusion Asian",value: false},
    "SI": {label: "Stepper SI",value: false},
    "STS": {label: "Stepper STS",value: false},
    "ZEI": {label: "Zeiss",value: false},
    "ARZ": {label: "Zeiss Architekt",value: false}
    */
   var discOpts = {
       aura:['AURA','AURFL'],
       convertibles:['CON','CONTI'],
       'gemini collection':['GM'],
       'gemini essentials':['GE'],
       'mersey beat eyewear':['MBE'],
       paparazzi:['PAP'],
       stepper:['SI','STS'],//,'E','FU','FUA'
       zeiss:['ZEI']
    };

    var brands = null,
        products = null;

    if(stateUserData && stateUserData.brands){
            brands = stateUserData.brands;
        }

    if(stateProductsData && stateProductsData.product){
        products = stateProductsData.product;
    }
    if(brands && products){

        Object.keys(brands).map(function(key, index) {

            if(brands[key].discount && brands[key].discount > 0){

                if(discOpts[key.toLowerCase()]){
                    for(var i = 0, k = discOpts[key.toLowerCase()].length; i < k; i++){

                        Object.keys(products).map(function(key2, index2) {
                            if(products[key2].subbrand == discOpts[key.toLowerCase()][i]){
                                for(var j = 0, m = products[key2].productinstance.length; j< m; j++){

                                    if(products[key2].productinstance[j].priceclearanceaud || products[key2].productinstance[j].priceclearancenzd){
//no discount as is clearance

                                    } else {
                                        products[key2].productinstance[j].price = (products[key2].productinstance[j].price/100 * (100 - brands[key].discount));
                                        products[key2].productinstance[j].normalprice = (products[key2].productinstance[j].normalprice/100 * (100 - brands[key].discount));
                                        products[key2].productinstance[j].discountnote = 'Your '+brands[key].discount+'% discount has been applied';
                                    }
                                }

                            }

                        });


                    }

                }

            }

        });

    }
    return stateProductsData;
}