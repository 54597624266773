export const CartActionTypes = {
  ADD_ITEM: "ADD_ITEM",
  RE_ADD_ITEM: "RE_ADD_ITEM",
  MINUS_ITEM: "MINUS_ITEM",
  REMOVE_ITEM: "REMOVE_ITEM",
  ADD_LAB_DIRECT_ITEM: "ADD_LAB_DIRECT_ITEM",
  MINUS_LAB_DIRECT_ITEM: "MINUS_LAB_DIRECT_ITEM",
  CLEAR_CART: "CLEAR_CART",

  


  UPDATE_QUANTITY: "UPDATE_QUANTITY",
  UPDATE_QUANTITY_LAB: "UPDATE_QUANTITY_LAB",
  SET_CART_ITEM_LAB_ENABLED: "SET_CART_ITEM_LAB_ENABLED",
  SET_JOB_NUM: "SET_JOB_NUM",
  SET_REFERENCE: "SET_REFERENCE",



  
  SET_STORE_DIRECT_STATE: "SET_STORE_DIRECT_STATE",
  SET_LAB_DIRECT_STATE: "SET_LAB_DIRECT_STATE",




  GET_COUPON: "GET_COUPON",
  FETCH_COUPON_SUCCESS: "FETCH_COUPON_SUCCESS",
  FETCH_COUPON_ERROR: "FETCH_COUPON_ERROR",

  IS_FETCHING: "CART_IS_FETCHING",
  NOT_FETCHING: "CART_NOT_FETCHING",

  GET_LAB_DIRECT_OPTIONS: "GET_LAB_DIRECT_OPTIONS",

  ADD_TO_WISHLIST:"ADD_TO_WISHLIST",
  REMOVE_FROM_WISHLIST:"REMOVE_FROM_WISHLIST"

};
