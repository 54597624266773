import React from "react"
import { buildForm } from "./BaseForm";
import { Controller } from "react-hook-form";

import * as yup from "yup"
import {
  Grid,
  Typography,
  TextField,
  DialogContent,
  MenuItem,
  Checkbox,
  InputLabel,
  Select,
  FormControlLabel,
  FormHelperText,
  FormControl
} from "@material-ui/core";


export const att = {
firstName: { name: "firstname", label: "First Name"},
middleName: { name: "middlename", label: "Middle Name"},
lastName: { name: "lastname", label: "Surname"},
email: { name: "email", label: "Email Address"},
newsletter: { name: "newsletter", label: "Newsletter"},
company: { name: "company", label: "Company Name"},
phoneNumber: { name: "phonenumber", label: "Telephone"},
street1: { name: "street1", label: "Street address"},
street2: { name: "street2", label: "Street address 2"},
city: { name: "city", label: "City"},
region: { name: "region", label: "State/Province"},
postcode: { name: "postcode", label: "Zip/Postal code"},
country: { name: "country", label: "Country"},
}

export const Validation = yup.object().shape({
  [att.firstName.name]: yup.string().required().label(att.firstName.label), 
  [att.middleName.name]: yup.string().label(att.middleName.label), 
  [att.lastName.name]: yup.string().required().label(att.lastName.label), 
  [att.email.name]: yup.string().email().required().label(att.email.label), 
  [att.newsletter.name]: yup.bool().required().label(att.newsletter.label), 
  [att.company.name]: yup.string().required().label(att.company.label), 
  [att.phoneNumber.name]: yup.string().required().label(att.phoneNumber.label), 
  [att.street1.name]: yup.string().required().label(att.street1.label), 
  [att.street2.name]: yup.string().label(att.street2.label), 
  [att.city.name]: yup.string().required().label(att.city.label), 
  [att.region.name]: yup.string().required().label(att.region.label), 
  [att.postcode.name]: yup.string().required().label(att.postcode.label), 
  [att.country.name]: yup.string().required().label(att.country.label), 
})


const FormTextItem = props => {
    return (
      <Grid item xs={12}>
        <Controller
            as={<TextField 
              error={typeof props.error !== "undefined"}
              className={props.classes.textWideField}
              type="text"
              margin="normal"
              label={props.label}
              helperText={(props.error||{message: "" }).message}
            />}
            name={props.name}
            control={props.control}
            defaultValue=""
            />
      </Grid>
    );
  };

export const RegisterFormBody = ({ 
  data = {}
, prefix
, footerProps = {}
, Footer
, validation: {
  errors,
  control
} = {
  errors: {}
}
,...props 
}) => {



    return (
      <>
      <DialogContent dividers={true}>
      <Grid
      container
      direction="column"
      justify="flex-start"
      alignItems="stretch"
    >
      {/* SIGN UP */}

      <Typography gutterBottom>
        Request an account to be able to place orders with <strong style={{color: "#34b3d2"}}>Optique&nbsp;Line</strong> by completing the below form.<br /><br />

      </Typography>

      <Typography variant="subtitle1">Personal information</Typography>

      <FormTextItem error={errors[att.firstName.name]} classes={props.classes} control={control} {...att.firstName} />
      <FormTextItem error={errors[att.middleName.name]} classes={props.classes} control={control} {...att.middleName} />
      <FormTextItem error={errors[att.lastName.name]} classes={props.classes} control={control} {...att.lastName} />
      <FormTextItem error={errors[att.email.name]} classes={props.classes} control={control} {...att.email} />

        <Grid item xs={12}>
            <Controller
                as={
                    <FormControlLabel
                    control={<Checkbox value="newsLetter"/>}
                    label="Sign up for Newsletter"
                    />
                }
                name={att.newsletter.name}
                control={control}
                defaultValue={true}
            />
            <FormHelperText>
                {errors.country && errors.country.message}
            </FormHelperText>
        </Grid>

  <Grid item xs={12}>
        <Typography variant="subtitle1">Address Information</Typography>
      </Grid>
      <FormTextItem error={errors[att.company.name]} classes={props.classes} control={control} {...att.company} />
      <FormTextItem error={errors[att.phoneNumber.name]} classes={props.classes} control={control} {...att.phoneNumber} />
      <FormTextItem error={errors[att.street1.name]} classes={props.classes} control={control} {...att.street1} />
      <FormTextItem error={errors[att.street2.name]} classes={props.classes} control={control} {...att.street2} />
      <FormTextItem error={errors[att.city.name]} classes={props.classes} control={control} {...att.city} />
      <FormTextItem error={errors[att.region.name]} classes={props.classes} control={control} {...att.region} />
      <FormTextItem error={errors[att.postcode.name]} classes={props.classes} control={control} {...att.postcode} />

      <Grid item xs={12}>
        
      <FormControl className={props.classes.formControl} error={typeof errors.country !== "undefined"}>
            <InputLabel id="register-select-country-label">{att.country.label}</InputLabel>
          <Controller
              as={
                <Select 
                labelId="register-select-country-label"
                id="register-select-country"
                className={props.classes.dropDownWide}>
                    <MenuItem value={"aus"}>Australia</MenuItem>
                    <MenuItem value={"nz"}>New Zealand</MenuItem>
                </Select>
              }
              name={att.country.name}
              control={control}
              defaultValue=""
            />
            <FormHelperText>
              {errors.country && errors.country.message}
            </FormHelperText>
      </FormControl>
      </Grid>
    </Grid>
      </DialogContent>
      <Footer {...footerProps} />
      </>
    );
  }
  
export const RegisterForm = buildForm({ FormBody: RegisterFormBody, Validation, themode: "onBlur" })

