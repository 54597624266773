import { combineReducers } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import userReducer from './user/user.reducer';
import productsReducer from './products/products.reducer';
import cartReducer from './cart/cart.reducer';
import cmsReducer from './cms/cms.reducer';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';


const cartPersistConfig = {
  key: 'cart',
  storage: storage,
  version: 2,
  whiteList:['wishList'],
  blacklist: ['coupon','labDirectOptions']
}

const productPersistConfig = {
  key: 'product',
  storage: storage,
  version: 2,
  blacklist:['error', 'isFetching', 'pending', 'filters','pagination','metadata','query']
}

const userPersistConfig = {
  key: 'user',
  storage: storage,
  version: 1,
  whitelist:['user']
}

const cmsPersistConfig = {
  key: 'cms',
  storage: storage,
  version: 1,
  blacklist:['logo']
}


const rootReducer = combineReducers({
    user: persistReducer(userPersistConfig, userReducer),
    cart: persistReducer(cartPersistConfig, cartReducer),
    product: persistReducer(productPersistConfig, productsReducer),
    cms: persistReducer(cmsPersistConfig, cmsReducer)
});

export default rootReducer;
