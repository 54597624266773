export const getFilters = () => ({
    "genderdescription": {
        label: "GENDER",
        type: 'in',
        filter: {
            "Child": {label: "Child",value: false},
            "Female": {label: "Female",value: false},
            "Male": {label: "Male",value: false},
            "Unisex": {label: "Unisex",value: false}
        }
    },
    "shapedescription": {
        label: "SHAPE",
        type: 'in',
        filter: {
            "Aviator": {label: "Aviator",value: false},
            "Circular": {label: "Circular",value: false},
            "Other": {label: "Other",value: false},
            "Oval": {label: "Oval",value: false},
            "Rectangle": {label: "Rectangle",value: false},
            "Square": {label: "Square",value: false}
        }
    },
    "productinstance_eyesize": {
        label: "EYE SIZE",
        type: 'between',
        filter: {
            "lower": {
                label: "Min",
                limit: 43,
                value: 43
            },
            "upper": {
                label: "Max",
                limit: 58,
                value: 58
            }
        }
    },
    "subbrand": {
        label: "BRAND",
        type: 'in',
        filter: {
            //"AURA": {label: "Aura Eyewear",value: false},
            "AURFL": {label: "Aura Flex",value: false},
            "CON": {label: "Convertibles Eyewear",value: false},
            "CONTI": {label: "Convertibles Titanium",value: false},
            "GM": {label: "Gemini Collection",value: false},
            "GE": {label: "Gemini Essentials",value: false},
            //"MAD": {label: "Mad in Italy",value: false},
            "MBE": {label: "Mersey Beat Eyewear",value: false},
            "PAP": {label: "Paparazzi",value: false},
            //"E": {label: "Stepper Eclectic",value: false},
            //"FU": {label: "Stepper Fusion",value: false},
           // "FUA": {label: "Stepper Fusion Asian",value: false},
            "SI": {label: "Stepper SI",value: false},
            "STS": {label: "Stepper STS",value: false},
            "ZEI": {label: "Zeiss",value: false},
            "ARZ": {label: "Zeiss Architekt",value: false}
        }
    },
    "construction": {
        label: "CONSTRUCTION",
        type: 'in',
        filter: {
            "Full Rim": {label: "Full Rim",value: false},
            "Lookover": {label: "Lookover",value: false},
            "Other": {label: "Other",value: false},
            "Rimless": {label: "Rimless",value: false},
            "Semi Rimless": {label: "Semi Rimless",value: false}
        }
    },
    "material": {
        label: "MATERIAL",
        type: 'in',
        filter: {
            "Metal": {label: "Metal",value: false},
            "Plastic": {label: "Plastic",value: false},
            "Titanium": {label: "Titanium",value: false},
            "TX5": {label: "TX5",value: false}
        }
    },
    "productinstance_colourgroup": {
        label: "COLOUR GROUP",
        type: 'in',
        filter: {
            "Black": {label: "Black",value: false},
            "Blue": {label: "Blue",value: false},
            "Brown": {label: "Brown",value: false},
            "Burgandy": {label: "Burgandy",value: false},
            "Copper": {label: "Copper",value: false},
            "Gold": {label: "Gold",value: false},
            "Green": {label: "Green",value: false},
            "Grey": {label: "Grey",value: false},
            "Gun Metal": {label: "Gun Metal",value: false},
            "Multi-colour": {label: "Multi-colour",value: false},
            "Neutral": {label: "Neutral",value: false},
            "Orange": {label: "Orange",value: false},
            "Pink": {label: "Pink",value: false},
            "Purple": {label: "Purple",value: false},
            "Red": {label: "Red",value: false},
            "Silver": {label: "Silver",value: false},
            "White": {label: "White",value: false}
        }
    }
})

// These Are Filters that will be applied for all queries made from this site
// Use the query format not the Filter formate
export const getStaticFilters = () => ({
    
})

export const getLabDirect = () => ({
"ESSILOR NZ": [
    { id: "1", region: "Auckland 0627" },
    { id: "2", region: "Christchurch 8024" }
  ],
"HOYA LENS NZ": [
    { id: "3", region: "Auckland 1024" }
  ],
"CARL ZEISS NZ": [
    { id: "4", region: "Auckland 1026" }
  ],
  "CR SURFACING": [{ id: "5", region: "Christchurch" }],
  "INDEPENDANT LENS": [{ id: "6", region: "Christchurch" }]
})

export const getTaxRate = () => 0.15;

export const getTerritoryManagers = () => ([
    {
        region: "VICTORIA",
        managers: [
            {
                name: "JOHN NICOLA",
                phone: "0418 322 658",
                email: "john@optiqueline.com.au"
            },
            {
                name: "ANDREW CRAIB",
                phone: "0400 168 754",
                email: "acraib@optiqueline.com.au"
            },
            {
                name: "ROY PARKER",
                phone: "0435 940 775",
                email: "roy@optiqueline.com.au"
            }
        ]
    },
    {
        region: "TASMANIA",
        managers: [
            {
                name: "ANDREW CRAIB",
                phone: "0400 168 754",
                email: "acraib@optiqueline.com.au"
            }
        ]
    },
    {
        region: "NEW SOUTH WALES",
        managers: [
            {
                name: "JOHN POLLACK",
                phone: "0412 258 875",
                email: "jpollack@optiqueline.com.au"
            },
            {
                name: "BETH BIRCH",
                phone: "0413 837 383‬",
                email: "beth@optiqueline.com.au"
            }
        ]
    },
    {
        region: "SOUTH AUSTRALIA",
        managers: [
            {
                name: "ROY PARKER",
                phone: "0435 940 775",
                email: "roy@optiqueline.com.au"
            }
        ]
    },
    {
        region: "WESTERN AUSTRALIA",
        managers: [
            {
                name: "ANDREW CRAIB",
                phone: "0400 168 754",
                email: "acraib@optiqueline.com.au"
            }
        ]
    },
    {
        region: "QUEENSLAND & NORTHERN TERRITORY",
        managers: [
            {
                name: "DOUG STABE",
                phone: "0418 782 848",
                email: "doug@optiqueline.com.au"
            },
            {
                name: "JASON HANRAHAN",
                phone: "0414 928 787‬",
                email: "jason@optiqueline.com.au"
            }
        ]
    },
])

