import { getMicroNavigationPaths, mapNavigationToExternal } from "../default/navigation"

//@ts-check
const stepperFilter = encodeURIComponent(JSON.stringify({
    "subbrand":{
        "type":"in",
        "value":[
                     //  "E",
           // "FU",
          //  "FUA",
          "SI",
          "STS"
        ]
    }
}))

const stepperSIFemaleFilter = encodeURIComponent(JSON.stringify({
    "subbrand":{
        "type":"in",
        "value":[            
            "SI"
        ]
    },
    "genderdescription":{
        "type":"in",
        "value":[
            "Female"
        ]
    },
}))

const stepperSTSMaleFilter = encodeURIComponent(JSON.stringify({
    "subbrand":{
        "type":"in",
        "value":[            
            "STS"
        ]
    },
    "genderdescription":{
        "type":"in",
        "value":[
            "Male"
        ]
    },
}))

const stepperSIFilter = encodeURIComponent(JSON.stringify({
    "subbrand":{
        "type":"in",
        "value":[            
            "SI"
        ]
    }
}))


const stepperFindAStockistFilter = "stepper"

export const getNavigationPaths = () => {
    return {
        ...getMicroNavigationPaths(),
        stepperSI: "/shop/stepper/si",
        stepperSIMale:"/shop/stepper/si/male",
        stepperSIFemale: "/shop/stepper/si/female"
    }
}


export const getNavigationStructure = () => {
    const paths = getNavigationPaths()
    return {
        product: {
          label: "The Product",
          links: {
            "Online Shop" : { label: "Online Shop", path: paths.shop },            
          }
        },        
    }
}


export const getNavigationExternals = () => {
    const paths = getNavigationPaths();
    return {
        ...mapNavigationToExternal("https://www.optiqueline.com.au",getNavigationPaths),
        [paths.shop]: `https://www.optiqueline.com.au/shop?filter=${stepperFilter}`,
        [paths.stepperSI]: `https://www.optiqueline.com.au/shop?filter=${stepperSIFilter}`,
        [paths.stepperSIFemale]: `https://www.optiqueline.com.au/shop?filter=${stepperSIFemaleFilter}`,
        [paths.stepperSIMale]: `https://www.optiqueline.com.au/shop?filter=${stepperSTSMaleFilter}`,
        [paths.findAStockist]: `https://www.optiqueline.com.au/findAStockist?brandreset=1&stockistbrand=${stepperFindAStockistFilter}`,
        [paths.becomeastockist]: "https://www.optiqueline.com.au/stockist-request-form"
    }
}

export const getNavigationStatics = () => {
    const paths = getNavigationPaths()
    return {
        // [paths.aboutUs]:"aboutus",
    }
}