//@ts-check

export const stepperFilter = encodeURIComponent(JSON.stringify({
    "subbrand":{
        "type":"in",
        "value":[
            //"E",
            // "FU",
            //"FUA",
            "SI",
            "STS"
        ]
    }
}))

export const convertiblesFilter = encodeURIComponent(JSON.stringify({
    "subbrand":{
        "type":"in",
        "value":[
            "CON",
            "CONTI"
        ]
    }
}))

export const getMicroNavigationPaths = () => ({
        aboutUs: "/aboutus",
        contactUs: "/contactus",
        findAStockist: "/findAStockist",
        becomeastockist: "/becomeastockist",    
        shop: `/shop`,    
        returns: "/returns",    
        faq:"/faq",
        delivery:"/delivery",
        terms:"/terms",
        forms:"/forms",
        privacy:"/privacy",
        marketingSupport: "/marketingSupport",
        stepperPromo: `/shop/stepperPromo`,
        convertiblesPromo: `/shop/convertiblesPromo`
    })
export const getNavigationPaths = () => ({
    aboutUs: "/aboutus",
    contactUs: "/contactus",
    findAStockist: "/findAStockist",
    becomeastockist: "/becomeastockist",
    blog: "/blog",
    marketingSupport: "/marketingSupport",
    shop: "/shop",
    clearance: "/clearance",
    spareParts: "/spareParts",
    newsLetter: "/newsLetter",
    returns: "/returns",
    brands: "/brands",
    accessories: "/accessories",
    faq:"/faq",
    delivery:"/delivery",
    terms:"/terms",
    forms:"/forms",
    privacy:"/privacy",
    component:"/component",
    account:"/account",
    productDetail:"/productDetail",
    cart:"/cart",
    newsletter:"/newsletter",
    order: '/order',
    territoryManagers: '/territoryManagers',
    authOnly: '/AuthorizedUsersOnly',
    brandsStepper: "/brands/Stepper",
    brandsConvertibles: "/brands/Convertibles",
    brandsBeat: "/brands/Beat",
    brandsZeiss: "/brands/Zeiss",
    brandsAuraFlex: "/brands/AuraFlex",
    brandsGemini: "/brands/Gemini",
    brandsPaparazzi: "/brands/Paparazzi",
    stepperPromo: `/shop?filter=${stepperFilter}`,
    convertiblesPromo: `/shop?filter=${convertiblesFilter}`
})

export const mapNavigationToExternal = (url,getNav = getMicroNavigationPaths) => {
    const paths = getNav()
    return {
        ...Object.keys(paths).reduce((out, x) => {
            out[paths[x]] = `${url}${paths[x]}`
            return out
        }, {}),
        '/shop/stepperPromo': `${url}/shop?filter=${stepperFilter}`,
        '/shop/convertiblesPromo' : `${url}/shop?filter=${convertiblesFilter}`
    }
} 

export const getNavigationStructure = () => {
    const paths = getNavigationPaths()
    return {
        company: {
            label: "The Company",
            links: {
                "About Us": { label: "About Us", path: paths.aboutUs },
                "Contact Us": { label: "Contact Us", path: paths.contactUs },
                "Find a Stockist": { label: "Find a Stockist", path: paths.findAStockist },
                "Blog": { label: "Blog", path: paths.blog },
                "Marketing Support": { label: "Marketing Support", path: paths.marketingSupport }
            }
        },
        product: {
          label: "The Product",
          links: {
            "Online Shop" : { label: "Online Shop", path: paths.shop },
            "Clearance Items" : { label: "Clearance Items", path: paths.clearance },
            "Spare Parts" : { label: "Spare Parts", path: paths.spareParts }
          }
        },
        brands: { 
            label: "The Brands", 
            links: {
                    "Stepper": { label: "Stepper", path: paths.brandsStepper},
                    "Convertibles": { label: "Convertibles", path: paths.brandsConvertibles},
                    "Beat": { label: "Mersey Beat", path: paths.brandsBeat},
                    "Zeiss": { label: "Zeiss", path: paths.brandsZeiss},
                    "AuraFlex": { label: "AuraFlex", path: paths.brandsAuraFlex},
                    "Gemini": { label: "Gemini", path: paths.brandsGemini},
                    "Paparazzi": { label: "Paparazzi", path: paths.brandsPaparazzi}
            }
        }
    }
}


export const getNavigationExternals = () => {
    const paths = getNavigationPaths();
    return {
        [paths.blog]: "http://blog.optiqueline.com.au/blog",
        [paths.becomeastockist]: "http://info.optiqueline.com.au/stockist-request-form",
        [paths.marketingSupport]: "http://info.optiqueline.com.au/marketing-support-australia",
        [paths.contactUs]: "http://info.optiqueline.com.au/contact-the-team",
        [paths.newsLetter]: "http://info.optiqueline.com.au/optique-line-newsletter-subscription",
        [paths.spareParts]: "http://info.optiqueline.com.au/spare-part-order-form",
        [paths.accessories]: "http://info.optiqueline.com.au/accessories",
        [paths.brands]: "http://info.optiqueline.com.au/optiquelinebrands",
        [paths.brandsStepper]:"http://info.optiqueline.com.au/stepper_eyewear",
        [paths.brandsConvertibles]:"http://info.optiqueline.com.au/convertibles_eyewear",
        [paths.brandsBeat]:"http://info.optiqueline.com.au/mersey-beat-eyewear",
        [paths.brandsZeiss]:"http://info.optiqueline.com.au/zeiss-eyewear",
        [paths.brandsAuraFlex]:"http://info.optiqueline.com.au/auraflex",
        [paths.brandsGemini]:"http://info.optiqueline.com.au/gemini-eyewear",
        [paths.brandsPaparazzi]:"http://info.optiqueline.com.au/paparazzi-eyewear"
    }
}

export const getNavigationStatics = () => {
    const paths = getNavigationPaths()
    return {
        [paths.aboutUs]:"aboutus",
        [paths.findAStockist]:"findstockist",
        [paths.faq]:"faq",
        [paths.delivery]:"delivery",
        [paths.terms]:"terms",
        [paths.forms]:"forms",
        [paths.privacy]:"privacy",
       // [paths.returns]:"returns",
    }
}