import * as optiquelineAu from './optiqueline.com.au'
import * as optiquelineNz from './optiqueline.co.nz'
import * as stepper from "./Steppereyewear.com.au"
import * as aura from "./auraeyewear.com.au"
import * as gemini from "./geminieyewear.com.au"
import * as merseybeat from "./Merseybeateyewear.com.au"
import * as convertibles from "./convertibleseyewear.com"

export const development = process.env.REACT_APP_DEPLOYMENT_ENV !== "prod";

const devSites = [
    "development.dn3nn8kj2eagn.amplifyapp.com",
    "master.dn3nn8kj2eagn.amplifyapp.com"
]

export const getURL = () => {   
    console.log("process.env.REACT_APP_DEPLOYMENT_ENV", process.env.REACT_APP_DEPLOYMENT_ENV)
    if(process.env.REACT_APP_DEPLOYMENT_ENV !== "prod" || devSites.includes(window.location.hostname)){
        const urlParams = new URLSearchParams(window.location.search);
        let setURL = urlParams.get('siteType');
        let storedURL = localStorage.getItem('siteType');
        let URL = setURL || storedURL || "optiqueline.com.au"
        localStorage.setItem('siteType',URL)
        return URL
    }else{
        return window.location.hostname;
    }
}


export const getSite = () => {
    let URL = getURL()
    if(URL in SITES){
        return SITES[URL]
    }else{
        return SITES["www.optiqueline.com.au"]
    }
}

const SITES = {
    "www.optiqueline.com.au": optiquelineAu,
    "optiqueline.com.au": optiquelineAu,
    "www.optiqueline.co.nz": optiquelineNz,
    "optiqueline.co.nz": optiquelineNz,
    "www.steppereyewear.com.au": stepper,
    "steppereyewear.com.au": stepper,
    "www.auraeyewear.com.au": aura,
    "auraeyewear.com.au": aura,
    "www.geminieyewear.com.au": gemini,
    "geminieyewear.com.au": gemini,
    "www.merseybeateyewear.com.au": merseybeat,
    "merseybeateyewear.com.au": merseybeat,
    "www.convertibleseyewear.com.au": convertibles,
    "convertibleseyewear.com.au": convertibles,
    "www.convertibleseyewear.com": convertibles,
    "convertibleseyeweare.com": convertibles,
    "localhost": optiquelineAu
}

export const SITE_TYPES = {
    NZ: "NZ",
    AU: "AU"
}


// export const getSite = () => {
//     return SITES["optiqueline.com.au"]
// }

export const getFilters = () => getSite().getFilters()
export const getStaticFilters = () => getSite().getStaticFilters()
export const getTheme = () => getSite().getTheme()
export const getPaging = () => getSite().getPaging()
export const getSiteType = () => getSite().getSiteType()
export const getAssets = () => getSite().getAssets()

export const getNavigation = () => getSite().navigation
export const getNavigationPaths = () => getSite().navigation.getNavigationPaths()
export const getNavigationStructure = () => getSite().navigation.getNavigationStructure()
export const getNavigationExternals = () => getSite().navigation.getNavigationExternals()
export const getNavigationStatics = () => getSite().navigation.getNavigationStatics()

export const getTerritoryManagers = () => getSite().getTerritoryManagers()

export const getLabDirect = () => getSite().getLabDirect()

export const getTaxRate = () => getSite().getTaxRate()




