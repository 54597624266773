import React, { useState, useRef, useEffect } from "react";
import { Link, useLocation, Redirect, withRouter } from "react-router-dom";

// Component Libraries
import { Grid, Hidden, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

// State Libraries
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';

// Redux Store Interface
import {
    setFilters
    , resetFilters
    , refreshProducts
    , setLimit
    , setOrder
    , setPaginations
    , unSetClearanceItemFilter
    , setClearanceItemFilter
    , setUrlFilter
} from '../../redux/products/products.action';
import {
    getProductsError
    , getProductsPending
    , selectFilters
    , selectPagination
    , selectMetadata
    , selectClearanceQuery
    , selectIsPending
    , getQuery
    , selectActiveFilterGroups
    , selectQueryFilter
} from '../../redux/products/products.selectors';

// Sub Components
import Accordion from '../../components/filter/Accordion'
import Pagination, { Pager } from './Pagination'
import ProductList from './ProductList';
import FeaturedLinks from '../../components/FeaturedLinks'

import { getAssets } from "../../microSiteConfig"
import './shop.scss';
import WithSpinner from '../../components/with-spinner/WithSpinner.component';
import { replaceInUrlQuery, pushUrlQuery } from "react-url-query";
import { addUrlProps, UrlQueryParamTypes } from 'react-url-query';
import HiddenIf from "../../components/HiddenIf";

import queryString from 'query-string';

const urlPropsQueryConfig = {
    filter: { type: UrlQueryParamTypes.string },
    siteType: { type: UrlQueryParamTypes.string },
};


const config = getAssets()

const ProductListWithSpinner = WithSpinner(ProductList);

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: theme.spacing(5),
    },
}));

const Shop = (props) => {
    const classes = useStyles();
    const location = useLocation();
    const [state, setDataState] = useState({
        isFetched: null,
    });

    const handleLimitChange = (event) => {
        console.log("handleLimitChange", event.target)
        props.setLimit(event.target.value)
        props.refreshProducts()
    };

    const handleOrderChange = (order) => {
        console.log("handleOrderChange", order)
        props.setOrder(order)
        props.refreshProducts()
    };

    const handleFilterChange = (filters) => {
        console.log("handleFilterChange=", filters);
        props.setFilters(filters)
        console.log(JSON.stringify(props.queryFilters) + "query filters")
        props.refreshProducts()
    }
    const handleFilterReset = () => {
        console.log("handleFilterReset");
        props.resetFilters()
        props.refreshProducts()
    }

    const changePagination = (offset) => {
        console.log("changePagination", offset)
        props.setPaginations(offset)
        props.refreshProducts(false)
    };

    //get initial data from products api
    async function fetchData() {
        if (location.pathname === '/clearance') { fetchClearanceData() }
        if (location.pathname === '/shop') { fetchShopData() }
        // props.resetFilters()
        props.refreshProducts()
        setDataState({
            isFetched: true,
        });

    }

    //get initial shop data from products api
    async function fetchShopData() {
        props.unSetClearanceItemFilter();
    }

    //get initial clearance data from products api
    async function fetchClearanceData() {
        props.setClearanceItemFilter();
    }

    useEffect(() => {
        fetchData();
    }, [location.pathname]);

    const selectQueryFilter = (filters) => {
        let query = {}
        Object.keys(filters).map(colKey => {
            const col = filters[colKey]
            switch (col.type) {
                case "in":
                    const selected = []
                    Object.keys(col.filter).map(filterKey => {
                        const filter = col.filter[filterKey]
                        if (filter.value) {
                            selected.push(filterKey)
                        }
                    })
                    if (selected.length > 0) {
                        query[colKey] = {
                            type: col.type,
                            value: selected
                        }
                    }
                    break
                case "between":
                    if (col.filter.lower.value !== col.filter.lower.limit || col.filter.upper.value !== col.filter.upper.limit) {
                        query[colKey] = {
                            type: col.type,
                            value: {
                                upper: col.filter.upper.value,
                                lower: col.filter.lower.value
                            }
                        }
                    }
            }
        })
        return query;
    };

    useEffect(() => {
        // let filterObject = selectQueryFilter(props.filters);
        // if (Object.keys(filterObject).length === 0) {        
        if (props.filter) {            
            props.setUrlFilter(JSON.parse(props.filter))
        }

        if (localStorage.getItem('eyewear_filter') == 'undefined') {
            localStorage.removeItem('eyewear_filter');
        } 
        
        if (localStorage.getItem('eyewear_filter') !== null) {                        
            props.setUrlFilter(JSON.parse(localStorage.getItem("eyewear_filter")));                        
            localStorage.removeItem('eyewear_filter');
        }

        let qs_params = queryString.parse(props.location.search);

        if(qs_params.filter){
            delete qs_params.filter;
            pushUrlQuery(qs_params);    
        }     
        if (props.siteType && props.siteType != 'optiqueline.co.nz') {            
            localStorage.setItem('eyewear_filter', props.filter);
            
            delete qs_params.siteType;
            pushUrlQuery(qs_params);
            
            window.location.reload();
        }
        // }
        // else {
        //     replaceInUrlQuery('filter', JSON.stringify(filterObject));
        // }
    }, [])

    return (
        <Grid container spacing={3} className={classes.root} >
            <Grid item xs={12} md={4} lg={3}>
                <Accordion
                    filters={props.filters}
                    activeFilterGroups={props.activeFilterGroups}
                    handleFilterChange={handleFilterChange}
                    handleFilterReset={handleFilterReset}
                />
                <HiddenIf value={typeof config.sideColumnLinks1 !== "undefined" && typeof config.sideColumnLinks1 !== "undefined"}>
                    <Hidden smDown>
                        <FeaturedLinks
                            featuredStubs={[...config.sideColumnLinks1, ...config.sideColumnLinks2]}
                            direction='column'
                        />
                    </Hidden>
                </HiddenIf>

            </Grid>

            <Grid item xs={12} md={8} lg={9}>
                <Hidden smDown>
                    <Grid item className="spacing-bottom">
                        <Typography variant="subtitle1">Available Products</Typography>
                    </Grid>
                </Hidden>
                <Grid item >
                    <Pagination paginationProps={props.pagination}
                        changePagination={changePagination}
                        handleOrderChange={handleOrderChange}
                        handleLimitChange={handleLimitChange}
                        meta={props.metadata} />
                </Grid>
                <Grid item>
                    <ProductListWithSpinner isLoading={props.isPending} />
                </Grid>
                <Grid item className="spacing-top">
                    <Pager meta={props.metadata} changePagination={changePagination} />
                </Grid>
            </Grid>
        </Grid>
    );
}

const mapStateToProps = createStructuredSelector({
    error: getProductsError,
    pending: getProductsPending,
    filters: selectFilters,
    queryFilters: getQuery,
    pagination: selectPagination,
    metadata: selectMetadata,
    clearanceQuery: selectClearanceQuery,
    isPending: selectIsPending,
    activeFilterGroups: selectActiveFilterGroups,
    selectQueryFilter: selectQueryFilter,
})

const mapDispatchToProps = dispatch => bindActionCreators({
    resetFilters,
    setFilters,
    setLimit,
    setOrder,
    setPaginations,
    refreshProducts,
    setClearanceItemFilter,
    unSetClearanceItemFilter,
    setUrlFilter
}, dispatch)


// export default connect(mapStateToProps, mapDispatchToProps)(Shop);
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(addUrlProps({ urlPropsQueryConfig })(Shop)));