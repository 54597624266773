import React from 'react';
import Carousel from 'react-bootstrap/Carousel';

export default (props) => {
    return (
        <div>
            <Carousel className='carousel'>
            {props.images.map((image,index) => (
                <Carousel.Item key={index}>
                    <img key={index} src={image} alt=""/>
                </Carousel.Item>
            ))}
            </Carousel>
        </div>
    );
};
