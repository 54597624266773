import { getLabDirect } from "../../microSiteConfig"

export const INITIAL_STATE = {
  cartItems: {},
  cartState: {},
  coupon: {},
  options: {},
  wishList: {},
  labDirectState: {
    state: "",
    region: ""
  },
  storeDirectState: {
    reference: "",
    comments: "",
    shippingInfo: "Standard"
  },
  shippingOption: "",
  labDirectOptions: getLabDirect()
};
