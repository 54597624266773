import React, { Fragment, useState, useRef, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ImageZoom from "./ImageZoom";
import { getProduct } from "../../services/product";
import ColourChooser from "./ColourChooser";

import MessageBar from "../../components/MessageBar";
import { addItem } from "../../redux/cart/cart.actions";
import queryString from "query-string";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { selectUserAuthStatus } from "../../redux/user/user.selectors";
import { removeFromWishList, addToWishList } from '../../redux/user/user.actions';
import AuthOnly from "../../components/AuthOnly";
import { withRouter, Link } from "react-router-dom";
import {
  Grid,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Button,
  ButtonGroup,
  CardMedia,
  Box
} from "@material-ui/core";

import "./productDetail.scss";
import HiddenIf from "../../components/HiddenIf";
import {
  selectWishList,selectBrands
} from "../../redux/user/user.selectors";

import {
  applyUserBrandDiscounts
} from "../../redux/products/products.utils";

import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';

import { getFilters } from "../../microSiteConfig";
import { properRounding, productFilterNameMatch } from "../../utils/helpers";

const theFilters = getFilters();

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(2)
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    minHeight: 0,
    seamless: true
  },
  priceWithSale: {
    //fontSize: "14px",
    color: "red",
    display: "inline"
  },
  priceWithoutSale: {
    fontSize: "14px",
    display: "inline",
    textDecoration: "line-through"
  },
  discountnoteclass: {
    fontSize: 11
  },
  iFrameCardMedia: {
    height: "395px",
    border: 0,
    marginTop: "-100px"
  },
  imgCardMedia: {
    border: 0,
    margin: "30px auto 50px",
    maxWidth: "640px"
  },
  table: {
    width: "100%"
  },
  box: {
    border: "solid 3px",
    margin: "0 2px",
    paddingLeft: "7px",
    paddingRight: "7px",
    borderRadius: "5px"
  }
}));

const ProductDetail = props => {
  const [isInWishList, setIsInWishList] = useState(false);
  const [product, setProduct] = useState({
    loaded: false,
    product: {}
  });
  const [state, setState] = useState({
    itemChosen: 0,
    itemChosenColorWatcher: "",
    itemChosenTitle: "",
    itemChosenColour: "",
    errorCount: 0,
    isSelected: true,
    imageLoaded: false
  });

  const handleChosenItem = (choice, choiceIndex) => {
    setState({
      itemChosen: choiceIndex,
      itemChosenColorWatcher: choice.colourswatch ? choice.colourswatch : "",
      itemChosenTitle: choice.title ? choice.title : "",
      itemChosenColour: choice.colour ? choice.colour : "",
      errorCount: 0,
      isSelected: true
    });
  };
  const [details, setDetails] = useState({
    details: {
      Brand: "__",
      Model: "Product Loading...",
      "Eye Size": "__",
      "Bridge Size": "__",
      Depth: "__",
      Gender: "__",
      Construction: "__",
      Material: "__",
      Shape: "__"
    },
    desc: {
      branddescription: "",
      materialdescription: ""
    },
    Modelheading: "",
  });
  const [imgError, setImgError] = useState({
    errorCount: 0
  });
  const classes = useStyles();

  const updateProductBackend = async productId => {
    let params = queryString.parse(productId);
    await getProduct(params.productId).then(shop => {
      console.log("res=", shop);
      
      setProduct({ loaded: true, product: shop });
    }).catch(error => {
      console.log("error=", error);
      setProduct({ loaded: false, product: null });
    });
  };

  const onImageError = e => {
    setImgError({ errorCount: imgError.errorCount + 1 });

    if (imgError.errorCount === 1) {
      e.target.src =
        product.product.productinstance[state.itemChosen].imageBackup;
    } else {
      e.target.src =
        product.product.productinstance[state.itemChosen].imageDefault;
    }
  };

  useEffect(() => {    
    if(product.loaded) {            
      if(Object.keys(props.wishList).includes(product.product.productid)) {
        setIsInWishList(true);
      }
    }
  }, [props.wishList, product]);

  useEffect(() => {    
    if(product.loaded) {            
      var thisprods = applyUserBrandDiscounts(props.brands,[product.product],1);
      handleChosenItem(product.product.productinstance[state.itemChosen], 0);
    }
  }, [props.brands, product]);


  useEffect(() => {
    if (!product.loaded) {
      updateProductBackend(props.location.search);
    }
  }, [props.location.search]);

  useEffect(() => {
    if (product.loaded) {
      setDetails({
        details: {
          Brand: productFilterNameMatch(product.product.subbrand,product.product.subbrandname),
          Model: product.product.productinstance[state.itemChosen].model,//product.product.productinstance[state.itemChosen].modelnotdisplayfixed?product.product.productinstance[state.itemChosen].modelnotdisplayfixed:
          "Eye Size": product.product.productinstance[state.itemChosen].eyesize,
          "Bridge Size":
            product.product.productinstance[state.itemChosen].bridge,
          Depth: product.product.productdepth,
          Gender: product.product.genderdescription,
          Construction: product.product.construction,
          Material: product.product.material,
          Shape: product.product.shapedescription
        },
        desc: {
          branddescription: product.product.branddescription,
          materialdescription: product.product.materialdescription
        },
        Modelheading: product.product.productinstance[state.itemChosen].model
      });
    }
  }, [product, state]);

  const handleAddToCart = triggerSuccess => {
    let data = { ...product.product };
    delete data.productinstance;
    props.addItem({
      ...data,
      ...product.product.productinstance[state.itemChosen]
    });
  };

  

  const toggleState = () => {
    if(!product.loaded) return;

    if(isInWishList){
        props.removeFromWishList({ ...product.product });
    } else{
        let productInstance = { ...product.product.productinstance[0], ...product.product};
        productInstance.productinstance = undefined;        
        props.addToWishList({ ...productInstance });
    }
    setIsInWishList(!isInWishList)
  }

  return (

    <Grid container spacing={3} className={classes.root}>
      {product.product ?
        <Fragment>
          <Grid item md={8} sm={12}>
            <Grid container>
              {product.product.productinstance && (
                <Grid item xs={12} className={classes.card}>
                  {typeof product.product.productinstance[state.itemChosen].pageasset === "undefined" || product.product.productinstance[state.itemChosen].pageasset === null ? (
                    null
                  ) : product.product.productinstance[state.itemChosen].pageasset.includes("html") ? (
                    <CardMedia
                    className={classes.iFrameCardMedia}
                    component={"iframe"}
                    alt="Glasses Viewer"
                    src={
                      product.product.productinstance[state.itemChosen].imageViewer
                    }
                    title="Glasses Viewer"
                  /> 
                  ) : (
                  <CardMedia
                    className={classes.imgCardMedia}
                    component={"img"}
                    alt="Glasses Viewer"
                    src={
                      product.product.productinstance[state.itemChosen].imageViewer
                    }
                    title="Glasses Viewer"
                    />

                  )}
                  
                </Grid>
              )}
              <Grid item xs={12}>
                <Typography variant="subtitle1" gutterBottom>
                  Product Description
            </Typography>
                <Typography variant="body1" className="desc">
                  {details.desc.branddescription}
                </Typography>
                <Typography
                  variant="body1"
                  className="desc"
                  style={{
                    paddingBottom: 0
                  }}
                >
                  {details.desc.materialdescription}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item md={4} sm={12}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12} m={6}>
                  <AuthOnly>
                  <Typography variant="inherit" display={"inline"} style={{float:'right', paddingTop: 10}}>
                      <HiddenIf value={isInWishList}>
                        <FavoriteIcon className="wishlistButton" onClick={toggleState} style={{ fontSize: 20, color: '#de9dac' }} />
                      </HiddenIf>
                      <HiddenIf value={!isInWishList}>
                        <FavoriteBorderIcon className="wishlistButton" onClick={toggleState} style={{ fontSize: 20, color: '#de9dac' }} />
                      </HiddenIf>
                    </Typography>
                    </AuthOnly>
                    <Typography variant="h6" display={"inline"}>
                      {productFilterNameMatch(product.product.subbrand,product.product.subbrandname)}
                      <br />{details.Modelheading}
                    </Typography>  
                                    
                  </Grid>
                  <Grid item xs={12} m={6}>
                    <Typography variant="inherit" className={classes.box}>
                      {details.details["Eye Size"] +
                        "." +
                        details.details["Bridge Size"]}
                    </Typography>
                    <Typography variant="inherit" className={classes.box}>
                      {details.details["Depth"]}
                    </Typography>                    
                  </Grid>                  

                  {product.product.productinstance && (
                    <>
                      <AuthOnly>
                        <Grid item xs={12}>
                          <Typography variant="inherit">
                            Availability:{" "}
                            {Number(
                              product.product.productinstance[state.itemChosen]
                                .stockavailable
                            ) === 0
                              ? "Out of Stock"
                              : "In Stock"}
                          </Typography>
                          <br />
                          <HiddenIf
                            value={
                              product.product.productinstance[state.itemChosen]
                                .clearanceitem === false
                            }
                          >
                            <Typography variant="subtitle1">
                              $
                          {properRounding(
                              product.product.productinstance[state.itemChosen]
                                .price
                            ,2,1)}{" "}
                          +GST
                        </Typography>
                          </HiddenIf>
                          <HiddenIf
                            value={
                              product.product.productinstance[state.itemChosen]
                                .clearanceitem === true
                            }
                          >
                            <Typography
                              variant="subtitle1"
                              className={classes.priceWithoutSale}
                            >
                              $
                          {properRounding(
                              product.product.productinstance[state.itemChosen]
                                .normalprice
                            ,2,1)}{" "}
                          +GST
                        </Typography>
                            <Typography
                              variant="subtitle1"
                              className={classes.priceWithSale}
                            >
                              &nbsp;$
                          {properRounding(
                              product.product.productinstance[state.itemChosen]
                                .clearanceprice
                            ,2,1)}{" "}
                          +GST
                        </Typography>
                          </HiddenIf>


                        {product.product.productinstance[state.itemChosen].discountnote?
                          <Typography className={classes.discountnoteclass}>
                          {product.product.productinstance[state.itemChosen].discountnote}
                          </Typography>
                          :null}

                        </Grid>
                      </AuthOnly>
                      <Grid item xs={12}>
                        <ColourChooser
                          choices={product.product}
                          itemChosen={state}
                          productIndex={product.product.productid}
                          handleChosenItem={handleChosenItem}
                          sizeMatters={0}
                        />
                      </Grid>
                      <AuthOnly>
                        <Grid item xs={12}>
                          <MessageBar thisStock={product.product.productinstance[state.itemChosen].stockavailable} handleAddToCart={handleAddToCart} />
                        </Grid>
                      </AuthOnly>
                    </>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1">Specifications</Typography>
                <TableContainer component={Paper}>
                  <Table
                    className={classes.table}
                    aria-label="simple table"
                    size="small"
                  >
                    <TableBody>
                      {Object.keys(details.details).map(key => (
                        <TableRow key={key}>
                          <TableCell component="th" scope="row">
                            {null}
                          </TableCell>
                          <TableCell align="left">{key}</TableCell>
                          <TableCell align="left">{details.details[key]}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Grid>
        </Fragment>
        :
        <Grid container direction="row" alignItems="center" justify="center" className="no_product_container">
          <Typography variant="subtitle1">
            Sorry, that product could not be found. Please try and <Link to="/shop">locate it here</Link> or <Link to="/contactus">contact us</Link> for assistance
          </Typography>
        </Grid>
      }
    </Grid>
  );
};
const mapStateToProps = createStructuredSelector({
  isLoggedIn: selectUserAuthStatus,
  wishList:selectWishList,
  brands:selectBrands
});

export default withRouter(connect(mapStateToProps, { addItem, addToWishList, removeFromWishList })(ProductDetail));
