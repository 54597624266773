// @ts-nocheck

import React, { useState } from "react";
import { withRouter, Link } from "react-router-dom";


import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";


import { Grid, Typography,CircularProgress } from "@material-ui/core";
import {
    selectUserEmail,
    selectOrderPending
} from "../redux/user/user.selectors";
import HiddenIf from "../components/HiddenIf";



const useStyles = makeStyles(theme => ({
    page:{
        paddingTop: theme.spacing(6),
        height: '71vh',
        minHeight: "230px"
    },
    textBox:{
        paddingLeft: '5%',
        paddingRight: '5%',
        [theme.breakpoints.up("sm")]: {
            paddingLeft: '5%',
            paddingRight: '5%',
        },
        [theme.breakpoints.up("md")]: {
            paddingLeft: '10%',
            paddingRight: '10%',
        },
        [theme.breakpoints.up("lg")]: {
            paddingLeft: '10%',
            paddingRight: '10%',
        }
    },
    textTitle: {
        fontSize: 25,
        [theme.breakpoints.up("sm")]: {
            fontSize: 30
        },
        [theme.breakpoints.up("md")]: {
            fontSize: 40
        },
        [theme.breakpoints.up("lg")]: {
            fontSize: 40
        }
    },
    textBody: {
        fontSize: 16,
        [theme.breakpoints.up("sm")]: {
            fontSize: 18
        },
        [theme.breakpoints.up("md")]: {
            fontSize: 20
        },
        [theme.breakpoints.up("lg")]: {
            fontSize: 24
        }
    }
}));

const CartPage = props => {
    const classes = useStyles()

  return (
      <>
        <HiddenIf value={props.orderPending}>
            <CircularProgress />
        </HiddenIf>
            <Grid container className={classes.page}>
                <Grid item xs={12} className={classes.textBox}>
                <HiddenIf value={!props.orderPending}>
                    <Typography variant="h4" className={classes.textTitle}>
                        Thank you for your order.
                    </Typography>
                    <br/>
                    <Typography variant="h6" className={classes.textBody}>
                        We have emailed your order details to you at {props.email || "..."}<br/> If there is an issue with your order please <Link to="/contactus">contact us</Link>.
                    </Typography>
                    </HiddenIf>
                </Grid>
            </Grid>   
      </>
 );
};

const mapStateToProps = createStructuredSelector({
    orderPending: selectOrderPending,
    email: selectUserEmail
});

const mapDispatchToProps = dispatch => ({

});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CartPage)
);
