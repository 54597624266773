import React, { useState, useRef, useEffect } from "react";
import { withStyles } from '@material-ui/core/styles';
import { Button, Menu, MenuItem, ListItemText, Grid, Box} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { makeStyles  } from '@material-ui/core';

const useStyles = makeStyles({
    buttonFullWidth: {
        width: "100%",
        display: 'flex',
        justifyContent: 'space-between'
    },
})
const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})(props => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles(theme => ({
    root: {
        paddingTop: 3,
        paddingBottom: 3,
        minWidth: "195px",
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);


export default function ColourChooser(props) {
    const [anchorEl, setAnchorEl] = useState(null);
    const classes = useStyles();
    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const syleMenuTextSpan = {
        fontSize: "15px"
    }

    const sizeClass = (sizeMatters, itemChosenColour, itemChosenTitle) => {

        itemChosenColour = itemChosenColour || "";
        itemChosenTitle = itemChosenTitle || "";
        var toRet = "",
            labelCheck = (itemChosenColour+" "+itemChosenTitle).length;

        if(sizeMatters && window.innerWidth > 599){
            if(labelCheck > 15){

                toRet = "text-label-chosen-extralong";

            } else if( labelCheck > 12 ){

                toRet = "text-label-chosen-long";

            }
        }
        return toRet;
    }

    return (
        <Grid container>
        <Grid item xs={12}>
            <Button
                size="small"
                variant="outlined"
                aria-controls="customized-menu"
                aria-haspopup="true"
                color="primary"
                className={classes.buttonFullWidth}
                onClick={event => handleClick(event)}
            >
                {(props.itemChosen.isSelected) ?
                    <>
                        <Box display={'inline'}>
                            <div className="colour-swatch icon" style={{ "background": "#" + props.itemChosen.itemChosenColorWatcher }}></div>
                        </Box>
                        <Box display={'inline'}>
                            <div className={`text-label text-label-chosen ${sizeClass(props.sizeMatters, props.itemChosen.itemChosenColour, props.itemChosen.itemChosenTitle)}`}>{props.itemChosen.itemChosenColour+" "+props.itemChosen.itemChosenTitle}</div>
                        </Box>
                    </>
                    :
                    <div className="text-label">Colours</div>
                }
                <ArrowDropDownIcon />
            </Button>

            <StyledMenu
                    id={props.productIndex}
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    >
                {props.choices.productinstance.map((choice, colIndex) => (
                    <div key={colIndex} >
                        {/* {JSON.stringify(choice)} */}
                        {choice.colourswatch && (
                            <StyledMenuItem onClick={() => {
                                props.handleChosenItem(choice, colIndex)
                                handleClose()
                            }} >
                                <div className="colour-swatch-small" style={{ "background": "#" + choice.colourswatch }}></div>
                                <ListItemText primaryTypographyProps={{ style: syleMenuTextSpan }} primary={choice.colour+" "+choice.title} />
                            </StyledMenuItem>
                        )}
                    </div>

                ))}
            </StyledMenu>
        </Grid>
        </Grid>
    );

}
