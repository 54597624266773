import React, { useState } from "react";
import CustomButton from "../custom-button/custom-button.component";
import { makeStyles, Box, Typography, TextField } from "@material-ui/core";
import HiddenIf from "../HiddenIf";
import * as yup from 'yup';
import { buildForm } from "./BaseForm"


const useStyles = makeStyles({
  formHeading: {
    padding: "10px",
    borderRadius: "3px",
    backgroundColor: "#212121",
    marginBottom: "10px",
    width: "100%"
  },
  formHeadingText: {
    paddingLeft: "20px",
    margin: "auto",
    textAlign: "center",
    width: "100%",
    fontSize: "25px",
    color: "#fff",


  },
  edit: {
    color: "#34b3d2",
    fontSize: "0.875em",
    "&:hover": {
      color: "#34b3d2"
    }
  },
  formField: {
    padding: "8px",
    width: "100%"
  },
  formInput: {
    marginBottom: "10px",
    padding: "10px",
    textAlign: "center",
    width: "100%"
  }
});


export const att = {
  company: {key: "company", label: "Company"},
  firstname: {key: "firstname", label: "First Name"},
  middlename: {key: "middlename", label: "Middle Name"},
  lastname: {key: "lastname", label: "Last Name"},
  street1: {key: "street1", label: "Address Line 1"},
  street2: {key: "street2", label: "Address Line 2"},
  city: {key: "city", label: "City"},
  region: {key: "region", label: "Region"},
  postcode: {key: "postcode", label: "Postcode"},
  phonenumber: {key: "phonenumber", label: "Phone Number"},
  country: {key: "country", label: "Country"},
}

export const Validation = yup.object().shape({
  [att.firstname.key]: yup.string().required().label(att.firstname.label), 
  [att.middlename.key]: yup.string().label(att.middlename.label), 
  [att.lastname.key]: yup.string().required().label(att.lastname.label), 
  [att.street1.key]: yup.string().required().label(att.street1.label), 
  [att.street2.key]: yup.string().label(att.street2.label), 
  [att.city.key]: yup.string().required().label(att.city.label), 
  [att.region.key]: yup.string().required().label(att.region.label), 
  [att.postcode.key]: yup.string().required().label(att.postcode.label), 
  [att.phonenumber.key]: yup.string().trim().required().label(att.phonenumber.label),//.matches(/^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/, { message:'Incorrect phone number format. Example +61 411122334', excludeEmptyString: true }).label(att.phonenumber.label), 
  [att.country.key]: yup.string().required().label(att.country.label), 
  [att.company.key]: yup.string().required().label(att.company.label), 
});

export const AddressFormBody = ({ 
  data = {}
, title
, disabled = false
, useBillingAddress = false
, display = true 
, saveAble = true
, isCart = false
, validation
, prefix=""
,...props 
}) => {
  const [ isDisplay , setIsDisplay  ] = useState(display)
  const classes = useStyles();
  const handleEdit = (event) => {
    if(display){
      setIsDisplay(false)
    }
    event.preventDefault()
  }
  const handleCancel = (event) => {
    if(display){
      setIsDisplay(true)
    }
    validation.reset(data)
  }

  let location = ""
  if(data.city !== "" && data.region !== "" && data.postcode){
    location = `${data.city},${data.region},${data.postcode}`
  }

  let telephone = ""
  if(data.phonenumber && data.phonenumber !== ""){
    telephone = `T: ${data.phonenumber}`
  }

  const errs = prefix.length?validation.errors[prefix]:validation.errors

  return (
    <Box className={classes.formField} >
      <Box align="center" className={classes.formHeading}>
        <Typography variant="h6" align="center" className={classes.formHeadingText}>{title}</Typography>
      </Box>
      <Box className={classes.spanContainer}>
        <HiddenIf value={isDisplay && !disabled && display}>
          <span className={classes.edit} onClick={(event) => handleEdit(event)}>
            Edit
          </span>
         
        </HiddenIf>
        &nbsp;&nbsp;
        <HiddenIf value={!isDisplay && !disabled}>
          <span className={classes.edit} onClick={(event) => handleCancel(event)}>
            {display ? "Close" : "Reset"}
         </span>
        </HiddenIf>
      </Box>
      <HiddenIf value={!isDisplay}>
          {Object.values(att).map( (item) => {
            const val = (useBillingAddress) ? validation.watch(`billingAddress.${item.key}`) : null
            return (
              <React.Fragment key={`${prefix}.${item.key}`}>
              {(!useBillingAddress && errs && typeof errs[item.key] !== "undefined") ?
              <Typography variant="subtitle2" style={{color: "red", fontSize: "15px"}}>{errs[item.key].message}</Typography> : null}
              {useBillingAddress ? <TextField
                className={classes.formInput}
                inputProps={{min: 0, style: { textAlign: 'center' }}}
                disabled={disabled}
                placeholder={item.label}
                variant="outlined"
                value={val}
                defaultValue={data[item.key]}
                name={`${prefix}.${item.key}`}
                inputRef={validation.register}
                onBlur={isCart?props.cartUpdateAddress(item.key):null}
              /> :
              <TextField
                className={classes.formInput}
                disabled={disabled}
                inputProps={{min: 0, style: { textAlign: 'center' }, defaultValue: data[item.key]}}
                placeholder={item.label}
                variant="outlined"
                name={`${prefix}.${item.key}`}
                inputRef={validation.register}
                onBlur={isCart?props.cartUpdateAddress(item.key):null}
              />}
            </React.Fragment>
            )
        })}
        <HiddenIf value={!disabled && saveAble}>
          <CustomButton type="submit" >Save</CustomButton>
        </HiddenIf>
      </HiddenIf>
      <HiddenIf value={isDisplay}>
        <p>
          {data.company}
          <br />
          {data.firstname} {data.middlename} {data.lastname}
          <br />
          {data.street1}
          <br />
          {data.street2}
          <br />
          {location}
          <br />
          {data.country}
          <br />
          {telephone}
          <br />
        </p>
      </HiddenIf>
    </Box>
  );
};


export const AddressForm = buildForm({
  FormBody: AddressFormBody,
  Validation,
  themode: "onBlur"
})
  


