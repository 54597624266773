//@ts-check
import { mapNavigationToExternal, getMicroNavigationPaths } from "../default/navigation"




const auraFilter = encodeURIComponent(JSON.stringify({
    "subbrand":{
        "type":"in",
        "value":[
            "AURFL"            
        ]
    }
}))

const findAStockistfilter = "auraflex";


export const getNavigationPaths = () => {
    return getMicroNavigationPaths()
}

export const getNavigationStructure = () => {
    const paths = getNavigationPaths()
    return {
        product: {
          label: "The Product",
          links: {
            "Online Shop" : { label: "Online Shop", path: paths.shop  },            
          }
        },        
    }
}

export const getNavigationExternals = () => {
    const paths = getNavigationPaths();
    return {
        ...mapNavigationToExternal("https://www.optiqueline.com.au",getNavigationPaths),
        [paths.shop]: `https://www.optiqueline.com.au/shop?filter=${auraFilter}`,
        [paths.findAStockist]: `https://www.optiqueline.com.au/findAStockist?brandreset=1&stockistbrand=${findAStockistfilter}`,
        [paths.becomeastockist]: "http://info.optiqueline.com.au/stockist-request-form"
    }
}


export const getNavigationStatics = () => {
    const paths = getNavigationPaths()
    return {
        // [paths.aboutUs]:"aboutus",
    }
}