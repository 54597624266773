import * as localNav from "../default/navigation";
import { SITE_TYPES } from "..";
import { getStaticFilters as defaultStaticFilters } from '../default/states'
export { getFilters, getLabDirect, getTaxRate } from "./states";
export { getTheme } from "./theme";
export { getPaging } from "../default/paging"
export { getAssets } from "./assets"
export { getTerritoryManagers } from "./assets"
export const navigation = localNav;


export const getSiteType = () =>  SITE_TYPES.NZ


export const getStaticFilters = () => ({
    ...defaultStaticFilters(),
    productinstance_pricenzd : {
        type: "isnull",
        value: false
    },
    productinstance_priceclearancenzd : {
        type: "isnull",
        value: false
    }
});