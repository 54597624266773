
const stepperFilter = encodeURIComponent(JSON.stringify({
    "subbrand":{
        "type":"in",
        "value":[
                       // "E",
           // ,
          //  "FUA",
          "SI",
          "STS"

        ]
    }
}))

const convertiblesFilter = encodeURIComponent(JSON.stringify({
    "subbrand":{
        "type":"in",
        "value":[
            "CON",
            "CONTI"
        ]
    }
}))

export const AssetBucket = "https://prod-optiqueline-258565051107-site-assets.s3-ap-southeast-2.amazonaws.com"


export const getTerritoryManagers = () => ({
    "New Zealand": {
        managers: [
          {
            name: "",
            phoneNumber: "0508 678 478",
            phoneNumberLink: "0508 678 478",
            email: "sales@optiqueline.com.au"
          }
        ]
      }
});

export const getAssets = () => ({
    logo: "/ol-main-logo.png",
    carousel: [
        `${AssetBucket}/homepage/a-l4.png`,
        `${AssetBucket}/homepage/b-l4.png`,
        `${AssetBucket}/homepage/c-l4.png`,
        `${AssetBucket}/homepage/d-l4.png`,
        `${AssetBucket}/homepage/e-l4.png`,
        `${AssetBucket}/homepage/f-l4.png`,
        `${AssetBucket}/homepage/h-l4.png`,
        `${AssetBucket}/homepage/i-l4.png`
    ],
    glasses: [
        {
            img:  `${AssetBucket}/homepage/stepper-sts-30035@2x.jpg`,
            link: "/productDetail?productId=30035-54-14"
        },
        {
            img: `${AssetBucket}/homepage/convertibles-91009@2x.jpg`,
            link: "/productDetail?productId=91009-53-16"
        },
        {
            img: `${AssetBucket}/homepage/aura-flex-628@2x.jpg`,
            link: "/productDetail?productId=628-53-18"
        },
        {
            img: "/stepper-sts-30114@2x.jpg",
            link: "/productDetail?productId=30114-53-13"
        },
        {
            img: `${AssetBucket}/homepage/mersey-beat-MORGA@2x.jpg`,
            link: "/productDetail?productId=MORGA-50-17"
        },
        {
            img: `${AssetBucket}/homepage/stepper-si-85037@2x.jpg`,
            link: "/productDetail?productId=85037-53-17"
        }
    ],
    homeGridRow1: [
        {
            img: `${AssetBucket}/homepage/1.jpg`,
            link: `/shop?filter=${convertiblesFilter}`
        },
        {
            img: `${AssetBucket}/homepage/2.jpg`,
            link: `/shop?filter=${stepperFilter}` 
        }
    ],
    homeGridRow2: [
        {
            img: `${AssetBucket}/homepage/home-row2-marketingsupport.jpg`,
            title: "MARKETING SUPPORT",
            link: "/marketingSupport"
        },
        {
            img: `${AssetBucket}/homepage/Brands_SMALL_BLOCKS2.jpg`,
            title: "BRANDS",
            link: "/brands"
        },
        {
            img: `${AssetBucket}/homepage/accessories_SMALL_BLOCKS.jpg`,
            title: "CASES & CLOTHS",
            link: "/accessories"
        },
        {
            img: `${AssetBucket}/homepage/Clearance_SMALL_BLOCKS4.jpg`,
            title: "CLEARANCE ITEMS",
            link: "/clearance"
        }
    ],
    homeGridRow3: [
        {
            img: `${AssetBucket}/homepage/3.jpg`,
            link: "/shop"
        }
    ],
    homeMiddleSentenceText: [
        {
            label: 'The Brands With Vision'
        }
    ],
    homeGridRow4: [
        {
            img: `${AssetBucket}/homepage/home-row4-partnerprogram.jpg`,
            title: "PARTNER PROGRAM",
            link: "/contactus"
        },
        {
            img: `${AssetBucket}/homepage/home-row4-seeandsave.jpg`,
            title: "FRAME & LENS",
            link: "/contactus"
        },
        {
            img: `${AssetBucket}/homepage/home-row4-labdirect.jpg`,
            title: "LAB DIRECT",
            link: "/contactus"
        },
        {
            img: `${AssetBucket}/homepage/home-row4-newsletter.jpg`,
            title: "NEWSLETTER",
            link: "/newsletter"
        }
    ],
    sideColumnLinks1: [
        {
            img: `${AssetBucket}/homepage/home-row4-partnerprogram.jpg`,
            title: "PARTNER PROGRAM",
            link: "/contactus"
        },
        {
            img: `${AssetBucket}/homepage/home-row4-labdirect.jpg`,
            title: "LAB DIRECT",
            link: "/contactus"
        },
        {
            img: `${AssetBucket}/homepage/home-row2-marketingsupport.jpg`,
            title: "MARKETING SUPPORT",
            link: "/marketingSupport"
        }
    ],
    sideColumnLinks2: [
        {
            img: `${AssetBucket}/homepage/1.jpg`,
            link: `/shop?filter=${convertiblesFilter}`
        },
        {
            img: `${AssetBucket}/homepage/2.jpg`,
            link: `/shop?filter=${stepperFilter}`
        }
    ],
    description: [
        {
            type: "h1",
            body: "helkle"
        }
    ],
    landingButtonRow: [
        {
            label: 'Contact Us',
            link: `/contactus`
        },
        {
            label: 'Subscribe to Newsletter',
            link: `/newsletter`
        }
    ]
})
